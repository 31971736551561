import { tokenRefresh } from '@/api/auth';
import { getDataType, showGlobalMessage } from '@/utils/function';
import { setStorage, getStorage, removeStorage } from '@/utils/storage';
import { DOUDOU_USERINFO, ERROR_CODE } from '@/utils/variables';

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
let isRefreshing = false;
let retryRequests: any[] = [];

export async function fetchReq(
  url: string,
  method: string,
  params: any,
  stream?: boolean,
  controller?: AbortController
): Promise<any> {
  try {
    const accessToken = getStorage<User.Info>(DOUDOU_USERINFO)?.access || '';
    const isFormData = getDataType(params) === 'FormData';
    const headers: HeadersInit = isFormData ? {} : { 'Content-Type': 'application/json' };
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;
    const body = isFormData ? params : JSON.stringify(params);
    const res: RequestInit = { method, headers };
    if (method !== 'GET') res.body = body;
    if (!!controller) res.signal = controller.signal;
    const response = await fetch(`${API_DOMAIN}${url}`, res);

    if (response.ok) {
      if (stream && response.body) {
        const reader = response.body.getReader();
        return reader;
      } else {
        const json = await response.json();
        return json;
      }
    } else {
      const json = await response.json();
      json.res = response;
      throw json;
    }
  } catch (err) {
    const { code, detail, res } = err as Api.Error;

    if (code === ERROR_CODE.tokenInvalid || res?.status === 401) {
      if (code === ERROR_CODE.userNotFound) {
        removeStorage(DOUDOU_USERINFO);
        window.location.reload();
        return;
      }

      const userInfo = getStorage<User.Info>(DOUDOU_USERINFO);

      if (!userInfo || url === '/auth/token_refresh') {
        removeStorage(DOUDOU_USERINFO);
        window.location.reload();
      } else {
        if (isRefreshing) {
          return new Promise(resolve => {
            retryRequests.push(() => resolve(fetchReq(url, method, params, stream, controller)));
          });
        } else {
          isRefreshing = true;
          const refreshToken = userInfo.refresh || '';
          const res = await tokenRefresh({ refresh: refreshToken });
          const info: User.Info = { ...userInfo, ...res };
          setStorage(DOUDOU_USERINFO, info);
          isRefreshing = false;
          retryRequests.forEach(req => {
            req();
          });
          retryRequests = [];
          return fetchReq(url, method, params, stream, controller);
        }
      }
    } else {
      if (!ERROR_CODE.subscriptionError.includes(code)) {
        console.error('api error', err);
        showGlobalMessage(detail, 'error');
      }
    }

    throw err;
  }
}
