export const TrialPackage: Subscription.Product = {
  id: 1,
  name: '体验版',
  currency: 'CNY',
  unit: 'm',
  price: 0,
  originalPrice: 0,
  policy: 100,
  hasPolicy: false,
  precision: 100,
  specification: { quota: 20 }
};

export const SmartPackage: Subscription.Product = {
  id: 2,
  name: '智慧版',
  currency: 'CNY',
  unit: 'm',
  price: 39.9,
  originalPrice: 39.9,
  policy: 100,
  hasPolicy: false,
  precision: 100,
  specification: { quota: 500 }
};

export const SmartRefuelingPackage: Subscription.Product = {
  id: 3,
  name: '智慧版加油包',
  currency: 'CNY',
  unit: 'm',
  price: 9.9,
  originalPrice: 9.9,
  policy: 100,
  hasPolicy: false,
  precision: 100,
  specification: { quota: 100 }
};

export function formatMoney(s: number, n: number): string {
  const digits = n > 0 && n <= 20 ? n : 2;
  const cost = `${parseFloat(`${s}`.replace(/[^\d.-]/g, '')).toFixed(digits)}`;
  const l = cost.split('.')[0].split('').reverse();
  const r = cost.split('.')[1];
  let t = '';

  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
  }

  return `${t.split('').reverse().join('')}.${r}`;
}

export function formatPolicy(currentPolicy: number): number {
  return Math.ceil(currentPolicy) / 10;
}

export function hasPolicy(currentPolicy: number): boolean {
  return currentPolicy < 100;
}
