export default function ErrorPage() {
  return (
    <div className="doudou-page error-page">
      <div className="center-container">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
      </div>
    </div>
  );
}
