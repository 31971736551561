import { ReactNode } from 'react';
import { Popover } from 'antd';
import cx from 'classnames';
import Bem from '@/utils/bem';
import './PopoverCard.less';

interface Props {
  children: ReactNode;
  content: ReactNode;
  overlayClassName: string;
  placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  trigger: string | string[];
  theme?: 'dark' | 'light';
}

const bem = new Bem('popover-card');

export default function PopoverCard({ children, content, overlayClassName, placement, trigger, theme }: Props) {
  return (
    <Popover
      content={content}
      overlayClassName={cx(bem.b(`${theme || 'dark'}`), overlayClassName)}
      placement={placement}
      trigger={trigger}
    >
      {children}
    </Popover>
  );
}
