import { MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import cx from 'classnames';
import { getTemplateList, getBookmarkTemplateList, addBookmarkTemplate, deleteBookmarkTemplate } from '@/api/template';
import { GlobalContext } from '@/pages/MainLayout';
import Blank from '@/components/global/Blank';
import ListItemCard from '@/components/ListItemCard';
import QuestionIcon from '@/icons/QuestionIcon';
import QRCode from '@/images/QRCode.jpg';
import Bem from '@/utils/bem';
import './TemplatePage.less';

const bem = new Bem('template-page');
const headerList = [
  { key: 'bookmarked', text: '模板收藏' },
  { key: 'Red', text: '小红书模板' },
  { key: 'Taobao', text: '淘宝模板' }
];

export default function TemplatePage() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { defaultProjId } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(headerList[1].key);
  const [bookmarkList, setBookmarkList] = useState<(Item.Template.BookmarkDetail & Item.Template.Detail)[]>([]);
  const [templateListMap, setTemplateListMap] = useState<{ [key: string]: Item.Template.Detail[] }>({});
  const [contact, setContact] = useState(false);

  const requestList = useCallback(async (init?: boolean) => {
    init && setLoading(true);
    const res = await Promise.all([requestBookmarkList(), requestTemplateList()]);
    setBookmarkList(res[0]);
    setTemplateListMap(res[1]);
    init && setLoading(false);
  }, []);

  const resetContact = useCallback(() => setContact(false), []);

  useEffect(() => {
    document.body.addEventListener('click', resetContact, false);

    return () => {
      document.body.removeEventListener('click', resetContact, false);
    };
  }, [resetContact]);

  useEffect(() => {
    setPageDetail({ isTemplates: true });
  }, [setPageDetail]);

  useEffect(() => {
    requestList(true);
  }, [requestList]);

  const requestBookmarkList = async (): Promise<(Item.Template.BookmarkDetail & Item.Template.Detail)[]> => {
    const res = await getBookmarkTemplateList();
    return res.results.map(t => ({ ...t, is_bookmarked: true }));
  };

  const requestTemplateList = async (): Promise<{ [key: string]: Item.Template.Detail[] }> => {
    const res = await getTemplateList();
    const listMap = {};

    (res || []).forEach(t => {
      const platform = t.template_metadata.tags?.platform || 'Unknown';

      if (!listMap[platform]) {
        listMap[platform] = [t];
      } else {
        listMap[platform].push(t);
      }
    });

    return listMap;
  };

  const onBookmark = async (id: string, data: { isBookmarked: boolean }) => {
    if (data.isBookmarked) {
      const index = bookmarkList.findIndex(b => b.template_metadata.name === id);
      if (index < 0) return;
      await deleteBookmarkTemplate(bookmarkList[index].id);

      if (selected === headerList[0].key) {
        const list = bookmarkList.concat();
        list.splice(index, 1);
        setBookmarkList(list);
      } else {
        const listMap = { ...templateListMap };
        const list = listMap[selected].concat();
        const item = list.find(t => t.template_metadata.name === id);
        if (!item) return;
        item.is_bookmarked = false;
        setTemplateListMap(listMap);
      }
    } else {
      const index = templateListMap[selected].findIndex(t => t.template_metadata.name === id);
      if (index < 0) return;
      await addBookmarkTemplate({ template_name: id });
      const listMap = { ...templateListMap };
      const list = listMap[selected].concat();
      list[index].is_bookmarked = true;
      setTemplateListMap(listMap);
    }

    requestList();
  };

  const onContactClick = (e: MouseEvent) => {
    e.stopPropagation();
    setContact(true);
  };

  const renderList = (concat?: boolean) => {
    const index = headerList.findIndex(h => h.key === selected);
    let list: Item.Template.Detail[] = [];

    if (index === 0) {
      list = bookmarkList;
    } else if (index > 0) {
      list = templateListMap[selected] || [];
    }

    return (
      <div className={bem.e('list-content', { empty: list.length <= 0 })}>
        {loading ? (
          <div className={bem.e('list-loading')} />
        ) : list.length > 0 ? (
          <>
            {list.map(item => (
              <ListItemCard
                key={item.template_metadata.name}
                className={bem.e('item')}
                item={item}
                type="template"
                onItemClick={() =>
                  navigate(`/projects/${defaultProjId}/completion`, {
                    state: { templateId: item.template_metadata.name }
                  })
                }
                onItemUpdate={(_type, _itemType, id, data) => onBookmark(id, data)}
                refreshItemList={requestList}
              />
            ))}
            {concat && (
              <div className={bem.e('contact-card', { concat })} onClick={onContactClick}>
                {contact ? (
                  <>
                    <img className={bem.e('qr-img')} src={QRCode} alt="DouDou QR Code" />
                    <div className={bem.e('tip')}>进群聊</div>
                  </>
                ) : (
                  <>
                    <div className={bem.e('contact-header')}>
                      <div className={bem.e('contact-icon')}>
                        <QuestionIcon />
                      </div>
                    </div>
                    <div className={bem.e('contact-content')}>
                      <div className={bem.e('contact-title')}>找不到模板？</div>
                      <div className={bem.e('contact-desc')}>
                        加入DouDou社群，提出您的宝贵建议。
                        <br />
                        更有众多福利等着你~
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <Blank tip="选择模板" />
        )}
      </div>
    );
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      <div className={bem.e('header-bar')}>
        {headerList.map(h => (
          <div
            key={h.key}
            className={bem.e('header-item', { selected: selected === h.key })}
            onClick={() => setSelected(h.key)}
          >
            {h.text}
          </div>
        ))}
      </div>
      <div className={bem.e('list-wrapper')}>{renderList(selected !== headerList[0].key)}</div>
    </div>
  );
}
