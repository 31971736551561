export function setStorage(key: string, value: unknown) {
  const encoded = JSON.stringify(value);
  window.localStorage.setItem(key, encoded);
}

export function getStorage<T extends unknown>(key: string): T | undefined {
  const encoded = window.localStorage.getItem(key);
  if (typeof encoded === 'string') {
    try {
      return JSON.parse(encoded);
    } catch (e) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function removeStorage(key: string) {
  window.localStorage.removeItem(key);
}
