import { useCallback, useContext, useEffect, useState } from 'react';
import { Result } from 'antd';
import QRCode from 'qrcode';
import { createOrder, getOrder } from '@/api/subscription';
import { GlobalContext } from '@/pages/MainLayout';
import Spinner from '@/components/global/Spinner';
import ModalContainer from '@/components/modal/ModalContainer';
import WechatIcon from '@/icons/WechatIcon';
import Bem from '@/utils/bem';
import './OrderModal.less';

interface Props {
  product: Subscription.Product | undefined;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const bem = new Bem('order-modal');
let timer: number;

export default function OrderModal({ product, visible, setVisible }: Props) {
  const { refreshSubscriptionInfo } = useContext(GlobalContext);
  const [expired, setExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const createPurchaseOrder = useCallback(async () => {
    if (!product) return;
    setLoading(true);
    const res = await createOrder({ product_id: product.id });
    QRCode.toDataURL(res.payment_qr_link, (error, url) => {
      if (error) {
        setVisible(false);
      } else {
        setOrderId(res.id);
        setImageSrc(url);
        setLoading(false);
      }
    });
  }, [product, setVisible]);

  const getOrderInfo = useCallback(async () => {
    if (!orderId) return;
    const { status } = await getOrder(orderId);

    if (status === 'EXPIRED') {
      setExpired(true);
    } else if (status === 'CONFIRMED') {
      await refreshSubscriptionInfo();
      setSuccess(true);
    } else if (status === 'PENDING') {
      timer = window.setTimeout(getOrderInfo, 2000);
    }
  }, [orderId, refreshSubscriptionInfo]);

  const close = useCallback(() => {
    setExpired(false);
    setLoading(true);
    setSuccess(false);
    setOrderId('');
    setImageSrc('');
  }, []);

  useEffect(() => {
    if (!visible) return;
    createPurchaseOrder();
  }, [visible, createPurchaseOrder]);

  useEffect(() => {
    timer && window.clearTimeout(timer);
    timer = window.setTimeout(getOrderInfo, 2000);

    return () => {
      timer && window.clearTimeout(timer);
    };
  }, [getOrderInfo]);

  const onRefresh = () => {
    setExpired(false);
    createPurchaseOrder();
  };

  return (
    <ModalContainer className={bem.b()} visible={visible} width={360} setVisible={setVisible} afterClose={close}>
      {success ? (
        <Result className={bem.e('success')} status="success" title="支付成功" />
      ) : (
        <>
          <div className={bem.e('price')}>￥{product?.price}</div>
          {expired && (
            <div className={bem.e('expired')} onClick={onRefresh}>
              二维码失效
              <br />
              请点击刷新
            </div>
          )}
          {loading && (
            <div className={bem.e('loading')}>
              <Spinner className={bem.e('spinner')} />
            </div>
          )}
          {!expired && !loading && <img className={bem.e('code-img')} src={imageSrc} alt="DouDou QR Code" />}
          <div className={bem.e('tip')}>
            <WechatIcon className={bem.e('wechat-icon')} />
            <span className={bem.e('tip-text')}>请用微信扫码完成支付</span>
          </div>
        </>
      )}
    </ModalContainer>
  );
}
