import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';
import { Button } from 'antd';
import cx from 'classnames';
import { getProfile } from '@/api/user';
import { GlobalContext } from '@/pages/MainLayout';
import TipList from '@/components/global/TipList';
import ConfirmModal from '@/components/modal/ConfirmModal';
import OrderModal from '@/components/modal/OrderModal';
import UpdateProfileModal from '@/components/modal/UpdateProfileModal';
import LogoutIcon from '@/icons/LogoutIcon';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';
import { TrialPackage, SmartPackage, SmartRefuelingPackage } from '@/utils/product';
import { removeStorage } from '@/utils/storage';
import { DATE_FORMATTER, formatDate } from '@/utils/time';
import { DOUDOU_USERINFO } from '@/utils/variables';
import './AccountPage.less';

const bem = new Bem('account-page');

export default function AccountPage() {
  const [state, copyToClipboard] = useCopyToClipboard();

  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { subscriptionInfo, setUserInfo, refreshSubscriptionInfo } = useContext(GlobalContext);
  const [profile, setProfile] = useState<User.Profile>();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [orderVisible, setOrderVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);

  const isPaid = subscriptionInfo && !subscriptionInfo.is_free_trial && subscriptionInfo.is_active;

  const requestProfile = useCallback(async () => {
    const res = await getProfile();
    setProfile(res);
  }, []);

  useEffect(() => {
    refreshSubscriptionInfo();
  }, [refreshSubscriptionInfo]);

  useEffect(() => {
    setPageDetail({});
  }, [setPageDetail]);

  useEffect(() => {
    requestProfile();
  }, [requestProfile]);

  useEffect(() => {
    if (!state.value) return;
    if (state.error) {
      showGlobalMessage('复制失败', 'error');
    } else {
      showGlobalMessage('已复制到剪贴板', 'success');
    }
  }, [state]);

  const onLogout = (confirm: boolean) => {
    setLogoutVisible(false);
    if (!confirm) return;
    removeStorage(DOUDOU_USERINFO);
    setUserInfo(undefined);
    navigate('/home', { replace: true });
  };

  const renderFreeInfo = () => {
    return (
      <>
        <div className={bem.e('subscription-info')}>
          <div className={bem.e('subscription-title')}>免费体验中</div>
          <div className={bem.e('subscription-meta')}>
            <div className={bem.e('subscription-price')}>{TrialPackage.price}元/月</div>
            {subscriptionInfo?.cur_period_quota && (
              <div className={bem.e('subscription-count')}>
                剩余<span className={bem.e('remain-count')}>{subscriptionInfo.cur_period_quota.remaining_quota}</span>次
              </div>
            )}
          </div>
          <TipList layout="horizontal" tips={['免费20次调用', '对话自由创作', '模板快速生成内容']} icon primary />
        </div>
        <Button className={bem.e('upgrade-btn')} type="primary" onClick={() => navigate('/subscription')}>
          升级套餐
        </Button>
      </>
    );
  };

  const renderPaidInfo = () => {
    return (
      <>
        <div className={bem.e('subscription-info')}>
          <div className={bem.e('subscription-title')}>月度会员</div>
          <div className={bem.e('subscription-meta')}>
            <div className={bem.e('subscription-price')}>{SmartPackage.price}元/月</div>
            {subscriptionInfo?.cur_period_quota && (
              <div className={bem.e('subscription-count')}>
                剩余<span className={bem.e('remain-count')}>{subscriptionInfo.cur_period_quota.remaining_quota}</span>次
              </div>
            )}
            {subscriptionInfo?.cur_period_quota && (
              <div className={bem.e('subscription-date')}>
                有效期至 {formatDate(subscriptionInfo.cur_period_quota.end_time, DATE_FORMATTER)}
              </div>
            )}
          </div>
          <TipList
            layout="portrait"
            tips={['使用量多达500次', '预设品牌风格，让兜兜学你说话', '抢先内测兜兜生图能力']}
            icon
            primary
          />
        </div>
        <div className={bem.e('package')}>
          <div className={bem.e('package-meta')}>
            <div className={bem.e('package-name')}>{SmartRefuelingPackage.name}</div>
            <div className={bem.e('package-detail')}>
              <span className={bem.e('package-price')}>￥{SmartRefuelingPackage.price}</span>
              <span className={bem.e('package-spec')}>/&nbsp;&nbsp;{SmartRefuelingPackage.specification?.quota}次</span>
            </div>
            <div className={bem.e('package-desc')}>智慧版兜兜罢工啦...没关系，你有秘密武器</div>
          </div>
          <Button className={bem.e('order-btn')} type="ghost" onClick={() => setOrderVisible(true)}>
            立即加油
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      <div className={bem.e('wrapper')}>
        <div className={bem.e('subscription')}>{isPaid ? renderPaidInfo() : renderFreeInfo()}</div>
        <div className={bem.e('container')}>
          <div className={bem.e('row')}>
            <div className={bem.e('meta')}>
              <div className={bem.e('title')}>用户ID</div>
              <div className={bem.e('value')}>{profile?.id || ''}</div>
            </div>
            <Button className={bem.e('btn')} onClick={() => copyToClipboard(profile?.id || '')}>
              复制
            </Button>
          </div>
          {profile?.current_phone_number && (
            <div className={bem.e('row')}>
              <div className={bem.e('meta')}>
                <div className={bem.e('title')}>手机</div>
                <div className={bem.e('value')}>{profile.current_phone_number}</div>
              </div>
              <Button className={bem.e('btn')} onClick={() => setUpdateVisible(true)}>
                更换
              </Button>
            </div>
          )}
          <div className={bem.e('row')}>
            <div className={bem.e('meta')}>
              <div className={bem.e('title')}>查看用户协议</div>
            </div>
            <Button className={bem.e('btn')} onClick={() => window.open('/terms.html')}>
              查看
            </Button>
          </div>
        </div>
        <Button className={bem.e('logout-btn')} onClick={() => setLogoutVisible(true)}>
          <LogoutIcon className={bem.e('btn-icon')} />
          <span className={bem.e('btn-text')}>退出登录</span>
        </Button>
      </div>
      <ConfirmModal
        message="要退出当前登录吗？"
        visible={logoutVisible}
        setVisible={setLogoutVisible}
        onClose={onLogout}
      />
      <OrderModal product={SmartRefuelingPackage} visible={orderVisible} setVisible={setOrderVisible} />
      <UpdateProfileModal visible={updateVisible} setVisible={setUpdateVisible} requestProfile={requestProfile} />
    </div>
  );
}
