import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import BlankIcon from '@/icons/BlankIcon';
import Bem from '@/utils/bem';
import './Blank.less';

interface Props {
  tip: string;
  path?: string;
}

const bem = new Bem('blank');

export default function Blank({ tip, path }: Props) {
  const navigate = useNavigate();

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!path) return;
    navigate(path);
  };

  return (
    <div className={bem.b({ clickable: !!path })} onClick={onClick}>
      <BlankIcon className={bem.e('icon')} />
      {tip && <div className={bem.e('tip')}>{tip}</div>}
      <div className={bem.e('tip')}>让doudou帮你创作</div>
    </div>
  );
}
