import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('plus-icon');

export default function PlusIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M11.999 2C12.5513 2 12.999 2.44772 12.999 3L12.999 21C12.999 21.5523 12.5513 22 11.999 22C11.4468 22 10.999 21.5523 10.999 21L10.999 13L2.99902 13C2.44678 13 1.99902 12.5523 1.99902 12C1.99902 11.4477 2.44678 11 2.99902 11L10.999 11L10.999 3C10.999 2.44772 11.4468 2 11.999 2ZM21.999 12C21.999 11.4477 21.5513 11 20.999 11L13.9976 11L13.9976 13L20.999 13C21.5513 13 21.999 12.5523 21.999 12Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="#2E2E2E"
        fillOpacity="1"
      />
    </svg>
  );
}
