import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('subscription-icon');

export default function SubscriptionIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19 21C21.209 21 23 19.209 23 17L23 7L22.9951 6.80029C22.8911 4.68408 21.1421 3 19 3L5 3C2.79102 3 1 4.79102 1 7L1 17C1 19.209 2.79102 21 5 21L19 21ZM5 4.5L19.0698 4.50098C20.4185 4.53809 21.5 5.64258 21.5 7L2.5 7L2.50098 6.93018C2.53809 5.58154 3.64258 4.5 5 4.5ZM2.5 9L21.5 9L21.5 17L21.499 17.0698C21.4619 18.4185 20.3574 19.5 19 19.5L5 19.5L4.93018 19.499C3.58154 19.4619 2.5 18.3574 2.5 17L2.5 9ZM11.25 10.5C11.6641 10.5 12 10.8359 12 11.25C12 11.6641 11.6641 12 11.25 12L4.75 12C4.33594 12 4 11.6641 4 11.25C4 10.8359 4.33594 10.5 4.75 10.5L11.25 10.5Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="#FFFFFF"
        fillOpacity="1"
      />
    </svg>
  );
}
