import moment, { Moment } from 'moment';

const BASE = 60;
export const DATE_FORMATTER = 'YYYY.MM.DD';
export const HOUR_FORMATTER = 'YYYY-MM-DD HH';
export const MINUTE_FORMATTER = 'YYYY-MM-DD HH:mm';
export const SECOND_FORMATTER = 'YYYY-MM-DD HH:mm:ss';

export function formatDate(date: Date | string | number | Moment, formatStr?: string): string {
  if (!date) return '';
  const format = formatStr || SECOND_FORMATTER;
  return moment(date).format(format);
}

export function secondsToMs(seconds: number): number {
  return seconds * 1000;
}

export function minutesToMs(minutes: number): number {
  return secondsToMs(BASE) * minutes;
}

export function hoursToMs(hours: number): number {
  return minutesToMs(BASE) * hours;
}

export function secondsToMinutes(seconds: number): number {
  return Math.floor(seconds / BASE);
}

export function minutesToHours(mins: number): { hours: number; minutes: number } {
  const hours = Math.floor(mins / BASE);
  const minutes = mins % BASE;
  return { hours, minutes };
}

export function minutesToDays(mins: number): { days: number; hours: number; minutes: number } {
  const hours = Math.floor(mins / BASE) % 24;
  const days = Math.floor(Math.floor(mins / BASE) / 24);
  const minutes = mins % BASE;
  return { days, hours, minutes };
}

export function secondsToHours(secs: number): { hours: number; minutes: number; seconds: number } {
  const minutes = secondsToMinutes(secs);
  const seconds = secs % BASE;
  return { ...minutesToHours(minutes), seconds };
}

export function secondsToDays(secs: number): { days: number; hours: number; minutes: number; seconds: number } {
  const minutes = secondsToMinutes(secs);
  const seconds = secs % BASE;
  return { ...minutesToDays(minutes), seconds };
}

export function padTime(time: number): string {
  return time >= 10 ? `${time}` : `0${time}`;
}

/**
 * Transform time in seconds into a formatted string by rules:
 * Under 1 minute - 00:SS
 * Under 10 minutes - 0M:SS
 * Under 1 hour - MM:SS
 * Under 10 hours - H:MM:SS
 */
export function getFormattedTime(secs: number): string {
  if (!secs || secs <= 0) return '00:00';

  const hourInSeconds = BASE * BASE;
  const { hours, minutes, seconds } = secondsToHours(secs);

  if (secs < hourInSeconds) {
    return `${padTime(minutes)}:${padTime(seconds)}`;
  } else {
    return `${hours}:${padTime(minutes)}:${padTime(seconds)}`;
  }
}
