import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputRef } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { updateChat } from '@/api/chat';
import { updateCompletion } from '@/api/completion';
import { updateProject } from '@/api/project';
import { GlobalContext } from '@/pages/MainLayout';
import PopoverCard from '@/components/global/PopoverCard';
import DetailMenu from '@/components/DetailMenu';
import ArrowIcon from '@/icons/ArrowIcon';
import CloseIcon from '@/icons/CloseIcon';
import PlusIcon from '@/icons/PlusIcon';
import QuestionIcon from '@/icons/QuestionIcon';
import QRCode from '@/images/QRCode.jpg';
import Bem from '@/utils/bem';
import { createPageHistory } from '@/utils/url';
import './Header.less';

interface Props {
  className: string;
}

const bem = new Bem('header');

export default function Header({ className }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    isDashboard,
    isProjectDetail,
    isChatDetail,
    isCompletionDetail,
    defaultProjId,
    nameInfo,
    pageInfo,
    projectList,
    updateNameInfo,
    refreshMenu
  } = useContext(GlobalContext);
  const inputRef = useRef<InputRef>(null);
  const [input, setInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [itemType, setItemType] = useState<Item.Type>();
  const [menuOpen, setMenuOpen] = useState(false);

  const pageHistory = useMemo(() => createPageHistory(pathname, nameInfo, pageInfo), [pathname, nameInfo, pageInfo]);

  useEffect(() => {
    if (!input || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [input]);

  const onCreateByTemplate = () => {
    navigate('/templates');
  };

  const onCreateByChat = () => {
    navigate(`/projects/${defaultProjId}/chat`);
  };

  const onSaveName = async () => {
    const { projectName, chatName, completionName } = nameInfo;
    const { projectId, chatId, completionId } = pageInfo;
    setInput(false);

    if (
      name === '' ||
      (itemType === 'project' && name === projectName) ||
      (itemType === 'chat' && name === chatName) ||
      (itemType === 'completion' && name === completionName) ||
      !projectId
    ) {
      return;
    }

    setLoading(true);

    try {
      if (itemType === 'project') {
        await updateProject(projectId, { name });
        refreshMenu();
      } else if (itemType === 'chat') {
        await updateChat(projectId, chatId || '', { operation: 'UPDATE_NAME', name });
        updateNameInfo(name, 'chat');
      } else if (itemType === 'completion') {
        await updateCompletion(projectId, completionId || '', { operation: 'UPDATE_NAME', name });
        updateNameInfo(name, 'completion');
      }
    } finally {
      setLoading(false);
    }
  };

  const onFileRename = (_id: string, type: Item.Type) => {
    setInput(true);
    setItemType(type);

    if (type === 'project') {
      setName(nameInfo.projectName || '');
    } else if (type === 'chat') {
      setName(nameInfo.chatName || '');
    } else if (type === 'completion') {
      setName(nameInfo.completionName || '');
    }
  };

  const onFileDelete = (type: Item.Type) => {
    if (type === 'project') {
      navigate('/dashboard');
    } else {
      navigate(`/projects/${pageInfo.projectId}`);
    }
  };

  const onClose = () => {
    navigate(`/projects/${pageInfo.projectId}`);
  };

  const renderPathArrowIcon = () => {
    const { itemId, type } = pageHistory[pageHistory.length - 1] || {};

    if ((isProjectDetail && pageInfo.projectId === defaultProjId) || !itemId || !type) {
      return null;
    }

    return (
      <DetailMenu
        id={itemId}
        curProjectId={pageInfo.projectId || ''}
        projectList={projectList}
        type={type}
        onRefresh={(projectId, itemId) => navigate(`/projects/${projectId}/${type}/${itemId}`)}
        onRename={onFileRename}
        onDelete={() => onFileDelete(type)}
        onOpenChange={setMenuOpen}
      >
        <div className={bem.e('arrow-icon', { hover: menuOpen })}>
          <ArrowIcon direction="down" />
        </div>
      </DetailMenu>
    );
  };

  const renderQuestionPopover = () => {
    return (
      <div className={bem.e('popover-content')}>
        <img className={bem.e('qr-img')} src={QRCode} alt="DouDou QR Code" />
        <div className={bem.e('tip')}>进群聊</div>
      </div>
    );
  };

  return (
    <header id="header" className={cx(className, bem.b())}>
      <div className={bem.e('left')}>
        {(isChatDetail || isCompletionDetail) && (
          <div className={bem.e('close-icon')} onClick={onClose}>
            <CloseIcon />
          </div>
        )}
        {pageHistory.map((p, i) => {
          const { path, title } = p;
          return (
            <Fragment key={path}>
              {i !== 0 && <div className={bem.e('split')}>/</div>}
              {input && i === pageHistory.length - 1 ? (
                <Input
                  ref={inputRef}
                  className={bem.e('name-input')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                  onBlur={onSaveName}
                  onPressEnter={onSaveName}
                />
              ) : (
                <div
                  className={bem.e('path', { disabled: path === pathname })}
                  onClick={() => path !== pathname && navigate(path)}
                >
                  {title || 'Untitled'}
                  {loading && i === pageHistory.length - 1 && <LoadingOutlined className={bem.e('input-loading')} />}
                </div>
              )}
            </Fragment>
          );
        })}
        {!input && !loading && renderPathArrowIcon()}
      </div>
      <div className={bem.e('right')}>
        {(isDashboard || isProjectDetail) && (
          <>
            <Button className={bem.e('create-btn')} type="primary" onClick={onCreateByChat}>
              <PlusIcon className={bem.e('btn-icon')} />
              <span className={bem.e('btn-text')}>通过对话创作</span>
            </Button>
            <Button className={bem.e('create-btn')} type="primary" onClick={onCreateByTemplate}>
              <PlusIcon className={bem.e('btn-icon')} />
              <span className={bem.e('btn-text')}>通过模板创作</span>
            </Button>
          </>
        )}
        <PopoverCard
          content={renderQuestionPopover()}
          overlayClassName={bem.e('popover-card')}
          placement="bottomRight"
          trigger="hover"
        >
          <div className={bem.e('icon-btn')}>
            <QuestionIcon className={bem.e('question-icon')} />
          </div>
        </PopoverCard>
      </div>
    </header>
  );
}
