import { fetchReq } from '@/utils/request';

export function getSubscription(subscriptionId: string): Promise<Subscription.Info> {
  return fetchReq(`/subscriptions/${subscriptionId}`, 'GET', {});
}

export function getSubscriptionList(params: {
  offset: number;
  limit: number;
}): Promise<{ count: number; results: Subscription.Info[] }> {
  return fetchReq(`/subscriptions?offset=${params.offset}&limit=${params.limit}`, 'GET', {});
}

export function createOrder(params: { product_id: number }): Promise<Subscription.Order> {
  return fetchReq('/orders', 'POST', params);
}

export function getOrder(orderId: string): Promise<Subscription.Order> {
  return fetchReq(`/orders/${orderId}`, 'GET', {});
}

export function getOrderList(params: {
  offset: number;
  limit: number;
}): Promise<{ count: number; results: Subscription.Order[] }> {
  return fetchReq(`/orders?offset=${params.offset}&limit=${params.limit}`, 'GET', {});
}
