type BemModifier = string | string[] | { [key: string]: boolean };

/**
 * A utility class for generating BEM classnames. See http://getbem.com/naming/.
 *
 * Use by storing an instance of this class as a constant above your component class e.g.
 *
 * const bem = new BemScope('my-component');
 *
 * Some examples:
 *
 * const bem = new BemScope('b');
 * bem.b();													// "b"
 * bem.b('m');												// "b b--m"
 * bem.b(['m1', 'm2', 'm3']);								// "b b--m1 b--m2 b--m3"
 * bem.b({ m1: false, m2: true, m3: false });				// "b b--m2"
 * bem.b('m1', ['m2', 'm3'], { m4: true, m5: false });		// "b b--m1 b--m2 b--m3 b--m4"
 *
 * bem.e('e');												// "b__e"
 * bem.e('e', 'm');											// "b__e b__e--m"
 * bem.e('e', ['m1', 'm2', 'm3']);							// "b__e b__e--m1 b__e--m2 b__e--m3"
 * bem.e('e', { m1: false, m2: true, m3: false });			// "b__e b__e--m2"
 * bem.e('e', 'm1', ['m2', 'm3'], { m4: true, m5: false });	// "b__e b__e--m1 b__e--m2 b__e--m3 b__e--m4"
 */
export default class Bem {
  private blockName: string;

  constructor(blockName = '') {
    this.blockName = blockName;
  }

  /**
   * Generates a class name for the root block of a component.
   *
   * @param {BemModifier[]} modifiers The modifier(s) to apply to the block name.
   * @returns	{string} A BEM compliant class name string.
   */
  b(...modifiers: BemModifier[]): string {
    return this.appendModifiers(this.blockName, modifiers);
  }

  /**
   * Generates a class name for a sub element within a component.
   *
   * @param {string} element The name of the element.
   * @param {BemModifier[]} modifiers The modifier(s) to apply to the element name.
   * @returns	{string} A BEM compliant class name string.
   */
  e(element: string, ...modifiers: BemModifier[]): string {
    return this.appendModifiers(`${this.blockName}__${element}`, modifiers);
  }

  private getModifierNames(modifier: BemModifier): string[] {
    if (Array.isArray(modifier)) {
      return modifier.filter(m => !!m);
    } else if (typeof modifier === 'object') {
      return Object.keys(modifier).filter(m => !!modifier[m]);
    } else if (modifier) {
      return [modifier];
    }
    return [];
  }

  private appendModifiers(name: string, modifiers?: BemModifier[]): string {
    if (modifiers?.length) {
      let modifierNames: string[] = [];
      for (const modifier of modifiers) {
        modifierNames = modifierNames.concat(this.getModifierNames(modifier));
      }
      if (modifierNames.length) {
        return name + ' ' + modifierNames.map(m => `${name}--${m}`).join(' ');
      }
    }
    return name;
  }
}
