import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  bookmarked?: boolean;
  className?: string;
}

const bem = new Bem('star-icon');

export default function StarIcon({ bookmarked, className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="21.744141" height="20.777954" viewBox="0 0 21.7441 20.778">
      {bookmarked ? (
        <path
          d="M9.99023 0.528442L7.25049 5.65234C7.10596 5.92236 6.84619 6.11133 6.54443 6.16516L0.824707 7.18738C0.0385742 7.328 -0.273926 8.2887 0.279785 8.86475L4.30664 13.0537C4.51855 13.2745 4.61816 13.5803 4.57617 13.8835L3.78076 19.6392C3.67139 20.4308 4.48877 21.0245 5.20752 20.6759L10.4355 18.141C10.7114 18.0073 11.0327 18.0073 11.3086 18.141L16.5366 20.6759C17.2554 21.0245 18.0728 20.4308 17.9634 19.6392L17.168 13.8835C17.126 13.5803 17.2256 13.2745 17.4375 13.0537L21.4644 8.86475C22.0181 8.2887 21.7056 7.328 20.9194 7.18738L15.1997 6.16516C14.8979 6.11133 14.6382 5.92236 14.4937 5.65234L11.7539 0.528442C11.377 -0.176147 10.3672 -0.176147 9.99023 0.528442Z"
          fillRule="evenodd"
          fill="#F7D26E"
        />
      ) : (
        <path
          d="M7.25049 5.65234C7.10596 5.92236 6.84619 6.11133 6.54443 6.16516L0.824707 7.18738C0.0385742 7.328 -0.273926 8.2887 0.279785 8.86475L4.30664 13.0537C4.51855 13.2745 4.61816 13.5803 4.57617 13.8835L3.78076 19.6392C3.67139 20.4308 4.48877 21.0245 5.20752 20.6759L10.4355 18.141C10.7114 18.0073 11.0327 18.0073 11.3086 18.141L16.5366 20.6759C17.2554 21.0245 18.0728 20.4308 17.9634 19.6392L17.168 13.8835C17.126 13.5803 17.2256 13.2745 17.4375 13.0537L21.4644 8.86475C22.0181 8.2887 21.7056 7.328 20.9194 7.18738L15.1997 6.16516C14.8979 6.11133 14.6382 5.92236 14.4937 5.65234L11.7539 0.528442C11.377 -0.176147 10.3672 -0.176147 9.99023 0.528442L7.25049 5.65234ZM9.01416 6.59534C8.58105 7.4054 7.80078 7.97241 6.89648 8.13403L3.01807 8.82715L5.74854 11.6677C6.38477 12.33 6.68311 13.2474 6.55713 14.1573L6.01807 18.0603L9.56299 16.3413C10.3896 15.9406 11.3545 15.9406 12.1812 16.3413L15.7261 18.0603L15.187 14.1573C15.061 13.2474 15.3594 12.33 15.9956 11.6677L18.7261 8.82715L14.8477 8.13403C13.9434 7.97241 13.1631 7.4054 12.73 6.59534L10.8721 3.12073L9.01416 6.59534Z"
          fillRule="evenodd"
          fill="#CCCCCC"
        />
      )}
    </svg>
  );
}
