import { useState } from 'react';
import { Button, Input } from 'antd';
import cx from 'classnames';
import ClearIcon from '@/icons/ClearIcon';
import Bem from '@/utils/bem';
import './InputTextArea.less';

interface Props {
  className: string;
  disableSend: boolean;
  btnAnimation?: boolean;
  defaultText?: string;
  onMsgSend: (msg: string) => Promise<void>;
}

const bem = new Bem('input-text-area');
const { TextArea } = Input;

export default function InputTextArea({ className, disableSend, btnAnimation, defaultText, onMsgSend }: Props) {
  const [value, setValue] = useState(defaultText || '');

  const onSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    if (disableSend || !value) return;
    setValue('');
    await onMsgSend(value);
  };

  return (
    <div className={cx(bem.b(), className)}>
      <TextArea
        style={{ resize: 'none' }}
        className={bem.e('text-area')}
        value={value}
        placeholder="请输入你要创作的内容主题文本"
        onChange={e => setValue(e.target.value)}
        onPressEnter={onSubmit}
      />
      <div className={bem.e('btns')}>
        <Button className={bem.e('clear-btn')} disabled={!value} onClick={() => setValue('')}>
          <ClearIcon />
        </Button>
        <Button
          className={bem.e('send-btn', { animation: !!btnAnimation })}
          type="primary"
          disabled={disableSend || !value}
          loading={disableSend}
          onClick={onSubmit}
        >
          发送
        </Button>
      </div>
    </div>
  );
}
