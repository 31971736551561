import { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button } from 'antd';
import cx from 'classnames';
import { GlobalContext } from '@/pages/MainLayout';
import TipList from '@/components/global/TipList';
import OrderModal from '@/components/modal/OrderModal';
import Bem from '@/utils/bem';
import { TrialPackage, SmartPackage, SmartRefuelingPackage } from '@/utils/product';
import './SubscriptionPage.less';

const bem = new Bem('subscription-page');

export default function SubscriptionPage() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { subscriptionInfo } = useContext(GlobalContext);
  const [product, setProduct] = useState<Subscription.Product>();
  const [orderVisible, setOrderVisible] = useState(false);

  const isPaid = subscriptionInfo && !subscriptionInfo.is_free_trial && subscriptionInfo.is_active;

  useEffect(() => {
    setPageDetail({});
  }, [setPageDetail]);

  const onOrderClick = (product: Subscription.Product) => {
    setProduct(product);
    setOrderVisible(true);
  };

  const renderTrialCard = () => {
    return (
      <div className={bem.e('card', 'trial')}>
        <div className={bem.e('card-title')}>免费体验</div>
        <div className={bem.e('card-meta')}>
          {TrialPackage.specification?.quota}次<span className={bem.e('tip')}>对话or模板</span>
        </div>
        <div className={bem.e('card-subTitle')}>新手博主？新手店主？兜兜带你快速上分</div>
        <TipList
          className={bem.e('tip-list')}
          layout="portrait"
          tips={['通过对话，快速获取售卖灵感', '通过模板，一键生成宝贝标题、卖点', '通过模板，一键生成小红书精品文案']}
          icon
          primary
        />
        <Button className={bem.e('trial-btn')} type="ghost" onClick={() => navigate('/dashboard')}>
          即刻体验
        </Button>
      </div>
    );
  };

  const renderPaidCard = () => {
    return (
      <div className={bem.e('card', 'paid')}>
        <div className={bem.e('pkg-wrapper')}>
          <div className={bem.e('left')}>
            <div className={bem.e('card-title')}>{SmartPackage.name}</div>
            <div className={bem.e('card-meta')}>
              ￥{SmartPackage.price}
              <span className={bem.e('tip')}>/&nbsp;&nbsp;月</span>
            </div>
            <div className={bem.e('card-subTitle')}>再也不用为文案苦恼，兜兜为你护航</div>
            <TipList
              className={bem.e('tip-list')}
              layout="portrait"
              tips={['使用量多达500次', '预设品牌风格，让兜兜学你说话', '抢先内测兜兜看图说话、卖点提炼']}
              icon
            />
          </div>
          <div className={bem.e('right')}>
            <div className={bem.e('pkg-info', { paid: !!isPaid })}>
              <div className={bem.e('info-title')}>{SmartRefuelingPackage.name}</div>
              <div className={bem.e('info-meta')}>
                ￥{SmartRefuelingPackage.price}
                <span className={bem.e('tip')}>/&nbsp;&nbsp;{SmartRefuelingPackage.specification?.quota}次</span>
              </div>
              <div className={bem.e('card-subTitle')}>智慧版兜兜罢工啦...没关系，你有秘密武器</div>
              <TipList
                className={bem.e('tip-list')}
                layout="portrait"
                tips={['为智慧版兜兜加量，100次', '智慧版不够用？加油包来助力', '主张绝不铺张浪费']}
                icon
              />
            </div>
            {isPaid && (
              <Button className={bem.e('order-btn')} type="ghost" onClick={() => onOrderClick(SmartRefuelingPackage)}>
                立即加油
              </Button>
            )}
          </div>
        </div>
        {!isPaid && (
          <Button className={bem.e('order-btn')} type="ghost" onClick={() => onOrderClick(SmartPackage)}>
            体验智慧版兜兜
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      <div className={bem.e('container')}>
        {renderTrialCard()}
        {renderPaidCard()}
      </div>
      <OrderModal product={product} visible={orderVisible} setVisible={setOrderVisible} />
    </div>
  );
}
