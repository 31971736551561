import { fetchReq } from '@/utils/request';

export function createCompletion(
  projectId: string,
  params: { template_input: string[]; template_name: string; template_version: number },
  controller: AbortController
): ReadableStreamDefaultReader<Uint8Array> {
  return fetchReq(`/projects/${projectId}/completions`, 'POST', params, true, controller) as any;
}

export function deleteCompletion(projectId: string, completionId: string): Promise<void> {
  return fetchReq(`/projects/${projectId}/completions/${completionId}`, 'DELETE', {});
}

export function updateCompletion(
  projectId: string,
  completionId: string,
  params: {
    operation: Api.UpdateCompletionOperation;
    completion_response_id?: string;
    description?: string;
    feedback?: Api.Feedback;
    name?: string;
    project_id?: string;
    template_input?: string[];
  },
  controller?: AbortController
): ReadableStreamDefaultReader<Uint8Array> {
  return fetchReq(`/projects/${projectId}/completions/${completionId}`, 'PATCH', params, true, controller) as any;
}

export function getCompletion(projectId: string, completionId: string): Promise<Item.Completion.Detail> {
  return fetchReq(`/projects/${projectId}/completions/${completionId}`, 'GET', {});
}

export function getCompletionList(
  projectId: string,
  params: { offset: number; limit: number; sortBy: Api.ListSortOption; queryAll?: boolean }
): Promise<{ count: number; results: Item.Completion.Detail[] }> {
  let url = `/projects/${projectId}/completions?offset=${params.offset}&limit=${params.limit}&sort_order=desc&sort_field=${params.sortBy}`;
  if (params.queryAll) url = `${url}&all_projects=True`;
  return fetchReq(url, 'GET', {});
}
