import { ReactNode } from 'react';
import { message } from 'antd';
import { SPLIT_DELIMITER } from './variables';

export function getBase64<T>(file: File): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as T);
    reader.onerror = err => reject(err);
  });
}

export function getDataType(obj: Object): string {
  return Object.prototype.toString.call(obj).slice(8, -1);
}

export function readStreamMessage(reader: ReadableStreamDefaultReader, cb: (done: boolean, data: any) => void) {
  const decoder = new TextDecoder();
  let bufferStr = '';

  const readData = (done: boolean, val: any) => {
    let str = decoder.decode(val);
    let data;
    const arr = str.split(SPLIT_DELIMITER);
    str = `${bufferStr}${arr[0]}`;
    bufferStr = arr[1] || '';

    try {
      data = JSON.parse(str);
    } catch (err) {
      data = str;
    }

    if (done) {
      bufferStr && cb(false, bufferStr);
      cb(true, null);
      return Promise.resolve();
    }

    cb(done, data);
    return reader.read().then(({ done, value }) => readData(done, value));
  };

  return reader.read().then(({ done, value }) => readData(done, value));
}

export function showGlobalMessage(content: ReactNode, type: 'success' | 'error', duration = 3, onClose?: () => void) {
  const config = { className: 'doudou-global-message', content, duration, onClose };
  message.destroy();
  message.config({ top: 100 });
  message[type](config);
}

export function throttle(fn: () => void, wait = 500) {
  let timer: number;
  return function () {
    if (!timer) {
      timer = window.setTimeout(() => {
        timer = 0;
        fn();
      }, wait);
    }
  };
}
