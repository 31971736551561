import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DashboardPage from '@/pages/DashboardPage';
import ErrorPage from '@/pages/ErrorPage';
import MainLayout from '@/pages/MainLayout';
import AccountPage from '@/pages/AccountPage';
import ChatDetail from '@/pages/project/ChatDetail';
import CompletionDetail from '@/pages/project/CompletionDetail';
import ProjectDetail from '@/pages/project/ProjectDetail';
import SubscriptionPage from '@/pages/SubscriptionPage';
import TemplatePage from '@/pages/project/TemplatePage';
import Bem from '@/utils/bem';
import './App.less';

const bem = new Bem('app-wrapper');

export default function App() {
  return (
    <div className={bem.b()}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout requiredAuth />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/templates" element={<TemplatePage />} />
            <Route path="/projects/:projectId">
              <Route index element={<ProjectDetail />} />
              <Route path="chat" element={<ChatDetail />}>
                <Route index />
                <Route path=":chatId" />
              </Route>
              <Route path="completion" element={<CompletionDetail />}>
                <Route index />
                <Route path=":completionId" />
              </Route>
            </Route>
            <Route path="/subscription" element={<SubscriptionPage />} />
            <Route path="/account" element={<AccountPage />} />
          </Route>
          <Route path="/home" element={<MainLayout />}>
            <Route index element={<DashboardPage />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
