import cx from 'classnames';
import CheckIcon from '@/icons/CheckIcon';
import Bem from '@/utils/bem';
import './TipList.less';

interface Props {
  layout: 'horizontal' | 'portrait';
  tips: string[];
  className?: string;
  icon?: boolean;
  primary?: boolean;
}

const bem = new Bem('tip-list');

export default function TipList({ layout, tips, className, icon, primary }: Props) {
  return (
    <div className={cx(bem.b(layout), className)}>
      {tips.map(t => (
        <span key={t} className={bem.e('tip')}>
          {icon && <CheckIcon className={bem.e('icon')} primary={primary} />}
          {t}
        </span>
      ))}
    </div>
  );
}
