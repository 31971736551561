import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('logout-icon');

export default function LogoutIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M17.75 2L17.9492 2.00525C19.9276 2.10876 21.5 3.74573 21.5 5.75C21.5 6.16418 21.1642 6.5 20.75 6.5C20.3358 6.5 20 6.16418 20 5.75C20 4.50732 18.9927 3.5 17.75 3.5L5.75 3.5L5.59595 3.50525C4.42517 3.58435 3.5 4.55908 3.5 5.75L3.5 17.75L3.50525 17.9041C3.58435 19.0748 4.55908 20 5.75 20L17.75 20L17.9041 19.9948C19.0748 19.9156 20 18.9409 20 17.75C20 17.3358 20.3358 17 20.75 17C21.1642 17 21.5 17.3358 21.5 17.75C21.5 19.821 19.821 21.5 17.75 21.5L5.75 21.5L5.55078 21.4948C3.57239 21.3912 2 19.7543 2 17.75L2 5.75L2.00525 5.55078C2.10876 3.57239 3.74573 2 5.75 2L17.75 2ZM18.4125 7.18945C18.1179 6.92712 17.6663 6.93726 17.3839 7.21973C17.1177 7.48596 17.0934 7.90259 17.3113 8.19617L17.3839 8.28027L20.103 11L11.7124 11C11.3191 11 11 11.3361 11 11.75C11 12.1639 11.3191 12.5 11.7124 12.5L20.104 12.5L17.3839 15.2197L17.3536 15.2517C17.0913 15.5463 17.1014 15.9979 17.3839 16.2803C17.6501 16.5466 18.0668 16.5708 18.3605 16.3529L18.4446 16.2803L21.7374 12.9874L21.7793 12.9442C22.3828 12.2983 22.406 11.307 21.8488 10.6348L21.7374 10.5126L18.4446 7.21973L18.4125 7.18945Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
