import { MouseEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, InputRef } from 'antd';
import cx from 'classnames';
import { createProject, updateProject } from '@/api/project';
import { GlobalContext } from '@/pages/MainLayout';
import PopoverCard from '@/components/global/PopoverCard';
import LoginModal from '@/components/modal/LoginModal';
import DetailMenu from '@/components/DetailMenu';
import AccountIcon from '@/icons/AccountIcon';
import CreateIcon from '@/icons/CreateIcon';
import LogoIcon from '@/icons/LogoIcon';
import SubscriptionIcon from '@/icons/SubscriptionIcon';
import QRCode from '@/images/QRCode.jpg';
import Bem from '@/utils/bem';
import './Sidebar.less';

interface Props {
  className: string;
  isAuth: boolean;
  projectList: Page.MenuSubItem[];
}

const bem = new Bem('sidebar');

export default function Sidebar({ className, isAuth, projectList }: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { pageInfo, subscriptionInfo, refreshSubscriptionInfo, refreshMenu } = useContext(GlobalContext);
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [renameId, setRenameId] = useState('');
  const [menuOpenId, setMenuOpenId] = useState('');

  const itemList = useMemo(() => {
    const list: Page.MenuItem[] = [
      {
        title: '我的创作',
        path: '/dashboard',
        icon: <CreateIcon />,
        subItems: projectList
      }
    ];
    return list;
  }, [projectList]);

  const isPaid = subscriptionInfo && !subscriptionInfo.is_free_trial && subscriptionInfo.is_active;

  useEffect(() => {
    isAuth && refreshMenu();
    isAuth && refreshSubscriptionInfo();
  }, [isAuth, refreshSubscriptionInfo, refreshMenu]);

  useEffect(() => {
    if (!renameId || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [renameId]);

  const onLinkClick = (path: string) => {
    navigate(path);
  };

  const onTitleClick = (e: MouseEvent, active: boolean, id: string) => {
    if (!active) return;
    e.stopPropagation();
    const project = projectList.find(p => p.id === id);
    if (!project || !project.icon || !!renameId) return;
    setProjectName(project.title);
    setRenameId(project.id);
  };

  const onCreateProject = async (e: MouseEvent) => {
    e.stopPropagation();

    const name = '新的文件夹';
    let projectName = name;
    let count = 0;

    // eslint-disable-next-line no-loop-func
    while (projectList.find(p => p.title === projectName)) {
      count++;
      projectName = `${name}${count}`;
    }

    const res = await createProject({ name: projectName });
    await refreshMenu();
    setProjectName(projectName);
    setRenameId(res.id);
  };

  const onRename = async () => {
    const project = projectList.find(p => p.id === renameId);

    if (!project || projectName === '' || projectName === project.title) {
      setProjectName('');
      setRenameId('');
      return;
    }

    try {
      await updateProject(renameId, { name: projectName });
      refreshMenu();
    } finally {
      setProjectName('');
      setRenameId('');
    }
  };

  const onDeleteProject = (id: string) => {
    if (pageInfo.projectId === id) {
      navigate('/dashboard');
    }
  };

  const onDefaultClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const renderCommonMenuPopover = () => {
    return (
      <div className={bem.e('popover-content')}>
        <img className={bem.e('qr-img')} src={QRCode} alt="DouDou QR Code" />
        <div className={bem.e('tip')}>进群聊</div>
      </div>
    );
  };

  const renderCommonMenu = () => {
    return (
      <div className={bem.e('common-menu')}>
        <Button className={bem.e('btn')} block onClick={() => setVisible(true)}>
          登录
        </Button>
        <PopoverCard
          content={renderCommonMenuPopover()}
          overlayClassName={bem.e('popover-card')}
          placement="rightBottom"
          trigger="hover"
        >
          <Button className={bem.e('btn')} block>
            帮助
          </Button>
        </PopoverCard>
        <LoginModal visible={visible} setVisible={setVisible} />
      </div>
    );
  };

  const renderAuthMenu = () => {
    return (
      <div className={bem.e('menu')}>
        <div className={bem.e('items-list')}>
          {itemList.map(item => (
            <div key={item.title} className={bem.e('section')}>
              <div
                className={cx(
                  bem.e('item', { active: ['/dashboard', '/home', '/templates'].includes(pathname) }),
                  ['/dashboard', '/home', '/templates'].includes(pathname) ? 'active' : ''
                )}
                onClick={() => onLinkClick(item.path)}
              >
                {item.title}
                {item.icon && (
                  <div className={bem.e('item-icon', 'suffix')} onClick={onCreateProject}>
                    {item.icon}
                  </div>
                )}
              </div>
              {item.subItems?.map(subItem => (
                <div
                  key={subItem.id}
                  className={cx(
                    bem.e('sub-item', {
                      active: pageInfo.projectId === subItem.id,
                      hover: menuOpenId === subItem.id || renameId === subItem.id
                    }),
                    pageInfo.projectId === subItem.id ? 'active' : ''
                  )}
                  onClick={() => onLinkClick(subItem.path)}
                >
                  {renameId === subItem.id ? (
                    <Input
                      ref={inputRef}
                      className={bem.e('project-name-input')}
                      value={projectName}
                      onChange={e => setProjectName(e.target.value)}
                      onBlur={onRename}
                      onPressEnter={onRename}
                      onClick={onDefaultClick}
                    />
                  ) : (
                    <span
                      className={bem.e('subItem-title')}
                      onClick={e => onTitleClick(e, pageInfo.projectId === subItem.id, subItem.id)}
                    >
                      {subItem.title}
                    </span>
                  )}
                  {subItem.icon && (
                    <DetailMenu
                      id={subItem.id}
                      curProjectId={subItem.id}
                      projectList={projectList}
                      type="project"
                      onRename={id => {
                        const project = projectList.find(p => p.id === id);
                        if (!project) return;
                        setProjectName(project.title);
                        setRenameId(id);
                      }}
                      onDelete={onDeleteProject}
                      onOpenChange={open => setMenuOpenId(open ? subItem.id : '')}
                    >
                      <div
                        className={bem.e('item-icon', 'suffix', { hover: menuOpenId === subItem.id })}
                        onClick={onDefaultClick}
                      >
                        {subItem.icon}
                      </div>
                    </DetailMenu>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={bem.e('footer-section')}>
          <div className={bem.e('line')} />
          <div
            className={cx(
              bem.e('item', { active: pathname === '/subscription' }),
              pathname === '/subscription' ? 'active' : ''
            )}
            onClick={() => onLinkClick('/subscription')}
          >
            <div className={bem.e('item-icon')}>
              <SubscriptionIcon />
            </div>
            套餐订阅
          </div>
          <div
            className={cx(bem.e('item', { active: pathname === '/account' }), pathname === '/account' ? 'active' : '')}
            onClick={() => onLinkClick('/account')}
          >
            <div className={bem.e('item-icon')}>
              <AccountIcon />
            </div>
            用户中心
          </div>
        </div>
      </div>
    );
  };

  return (
    <aside id="sidebar" className={cx(className, bem.b({ isAuth }))}>
      <div className={bem.e('logo')} onClick={() => navigate(isAuth ? '/dashboard' : '/home')}>
        <LogoIcon className={bem.e('logo-icon')} />
      </div>
      {subscriptionInfo && <div className={bem.e('badge')}>{isPaid ? '月度会员' : '免费体验'}</div>}
      {isAuth ? renderAuthMenu() : renderCommonMenu()}
    </aside>
  );
}
