import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, message } from 'antd';
import cx from 'classnames';
import { createCompletion, updateCompletion, getCompletion } from '@/api/completion';
import { getTemplate } from '@/api/template';
import { GlobalContext } from '@/pages/MainLayout';
import ChatMessageBox from '@/components/ChatMessageBox';
import TemplateForm from '@/components/TemplateForm';
import CloseIcon from '@/icons/CloseIcon';
import Bem from '@/utils/bem';
import { getDataType, readStreamMessage, showGlobalMessage } from '@/utils/function';
import { ERROR_CODE } from '@/utils/variables';
import './CompletionDetail.less';

const bem = new Bem('completion-detail-page');
let controller: AbortController;

export default function CompletionDetail() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { projectId, completionId } = useParams();
  const { updateNameInfo } = useContext(GlobalContext);
  const dataRef = useRef<{
    completionId?: string;
    newCompletionId?: string;
    aiMessage?: Item.Completion.Message;
    templateInput?: string[];
  }>({});
  const [newCompletionId, setNewCompletionId] = useState('');
  const [completionName, setCompletionName] = useState('');
  const [template, setTemplate] = useState<Item.Template.Metadata>();
  const [resProgress, setResProgress] = useState(false);
  const [aiMessage, setAiMessage] = useState<Item.Completion.Message>();
  const [templateInput, setTemplateInput] = useState<string[]>([]);

  const refreshCompletion = useCallback(async () => {
    if (!projectId || !dataRef.current.completionId) return;

    try {
      const res = await getCompletion(projectId, dataRef.current.completionId);
      if (!res.template) return;
      setCompletionName(res.name);
      setTemplateInput(res.template_input);
      setTemplate(res.template);
      setAiMessage(res.responses[0]);
    } catch (err) {
      navigate(`/projects/${projectId}`, { replace: true });
    }
  }, [projectId, navigate]);

  useEffect(() => {
    setPageDetail({ isCompletionDetail: true });
  }, [setPageDetail]);

  useEffect(() => {
    dataRef.current.completionId = completionId;
    dataRef.current.newCompletionId = newCompletionId;
    dataRef.current.aiMessage = aiMessage;
    dataRef.current.templateInput = templateInput;
  }, [completionId, newCompletionId, aiMessage, templateInput]);

  useEffect(() => {
    updateNameInfo(completionName, 'completion');
  }, [completionName, updateNameInfo]);

  useEffect(() => {
    refreshCompletion();
  }, [refreshCompletion]);

  useEffect(() => {
    if (!state?.templateId) return;
    getTemplate(state.templateId).then(res => {
      const { line_template_list } = res.template;
      if (!line_template_list || line_template_list.length <= 0) return;
      const templateInput = line_template_list.map(line => {
        if (line.type === 'select') return line.select_default_value || '';
        else return '';
      });
      setTemplateInput(templateInput);
      setTemplate(res.template);
    });
  }, [state?.templateId]);

  const onGenerate = async () => {
    if (!projectId || !template) return;

    let reader;
    controller = new AbortController();

    setResProgress(true);
    setAiMessage({ id: 'ai-temp', content: '', create_time: '', feedback: 'NONE' });

    try {
      if (completionId) {
        reader = await updateCompletion(
          projectId,
          completionId,
          { operation: 'REGENERATE_COMPLETION_RESPONSE', template_input: templateInput },
          controller
        );
      } else {
        reader = await createCompletion(
          projectId,
          { template_input: templateInput, template_name: template.name, template_version: template.version },
          controller
        );
      }

      await readStreamMessage(reader, (done, data) => {
        if (done) {
          setResProgress(false);

          if (dataRef.current.newCompletionId) {
            navigate(`/projects/${projectId}/completion/${dataRef.current.newCompletionId}`);
          }

          return;
        }

        const dataType = getDataType(data);
        const newResponse = { ...dataRef.current.aiMessage };

        if (dataType !== 'String') {
          const { id } = data;
          if (!id) return;
          if (!dataRef.current.completionId) setNewCompletionId(id);
          newResponse.id = '';
        } else {
          newResponse.content = `${newResponse.content}${data}`;
        }

        setAiMessage(newResponse as Item.Completion.Message);
      });
    } catch (err) {
      const { code } = err as Api.Error;

      if (ERROR_CODE.subscriptionError.includes(code)) {
        showGlobalMessage(
          <>
            您的用量已耗尽，请&nbsp;
            <Button
              type="link"
              onClick={() => {
                message.destroy();
                navigate('/subscription');
              }}
            >
              订阅
            </Button>
            &nbsp;后继续创作
          </>,
          'error',
          0
        );
      }

      onAbort();
    }
  };

  const onAbort = () => {
    controller?.abort();
    setResProgress(false);
  };

  const onMessageFeedback = (messageId: string, feedback: Api.Feedback) => {
    if (messageId !== aiMessage?.id) return;
    setAiMessage({ ...aiMessage, feedback });
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      <div className={bem.e('line')} />
      <div className={bem.e('left')}>
        <div className={bem.e('wrapper')}>
          <TemplateForm
            completionId={completionId || ''}
            resProgress={resProgress}
            template={template}
            templateInput={templateInput}
            setTemplateInput={setTemplateInput}
            onGenerate={onGenerate}
          />
        </div>
      </div>
      <div className={bem.e('right')}>
        <div className={bem.e('wrapper')}>
          {aiMessage && (
            <ChatMessageBox
              itemType="completion"
              completionMessage={aiMessage}
              avatarPosition="right"
              className={bem.e('msg-box')}
              inner={aiMessage.content}
              isDoudou
              loading={resProgress}
              onFeedbackCb={onMessageFeedback}
            />
          )}
        </div>
        {resProgress && (
          <Button className={bem.e('stop-btn')} onClick={onAbort}>
            <CloseIcon className={bem.e('btn-icon')} />
            <span className={bem.e('btn-text')}>停止生成</span>
          </Button>
        )}
      </div>
    </div>
  );
}
