import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('ellipsis-icon');

export default function EllipsisIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.80493 10C7.80493 8.80955 8.78052 7.85717 10 7.85717C11.2195 7.85717 12.1951 8.80955 12.1951 10C12.1951 11.1905 11.2195 12.1429 10 12.1429C8.78052 12.1429 7.80493 11.1905 7.80493 10ZM2.19531 12.1429C3.41455 12.1429 4.39014 11.1905 4.39014 10C4.39014 8.80955 3.41455 7.85717 2.19531 7.85717C0.975586 7.85717 0 8.80955 0 10C0 11.1905 0.975586 12.1429 2.19531 12.1429ZM17.8049 12.1429C19.0244 12.1429 20 11.1905 20 10C20 8.80955 19.0244 7.85717 17.8049 7.85717C16.5854 7.85717 15.6099 8.80955 15.6099 10C15.6099 11.1905 16.5854 12.1429 17.8049 12.1429Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
