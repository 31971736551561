import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
  primary?: boolean;
}

const bem = new Bem('check-icon');

export default function CheckIcon({ className, primary }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M16.875 5.625L8.125 14.375L3.75 10"
        stroke={primary ? '#9FC653' : 'currentColor'}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  );
}
