import { fetchReq } from '@/utils/request';

export function createProject(params: { name: string }): Promise<Item.Project.Detail> {
  return fetchReq('/projects', 'POST', params);
}

export function deleteProject(projectId: string): Promise<void> {
  return fetchReq(`/projects/${projectId}`, 'DELETE', {});
}

export function updateProject(projectId: string, params: { name: string }): Promise<void> {
  return fetchReq(`/projects/${projectId}`, 'PATCH', params);
}

export function getProject(projectId: string): Promise<Item.Project.Detail> {
  return fetchReq(`/projects/${projectId}`, 'GET', {});
}

export function getProjectList(params: {
  offset: number;
  limit: number;
}): Promise<{ count: number; results: Item.Project.Detail[] }> {
  return fetchReq(`/projects?offset=${params.offset}&limit=${params.limit}`, 'GET', {});
}
