import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('login-icon');

export default function LoginIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.82178 17.3062C7.75293 20.4843 3.51562 20.4843 3.51562 20.4843C3.51562 20.4843 3.51562 16.2469 6.69385 15.1781"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M12 16.2469L7.75293 12L14.1187 5.63438C16.5278 3.22501 18.9375 3.25314 19.9688 3.40314C20.1279 3.42436 20.2754 3.49738 20.3892 3.61089C20.5024 3.72441 20.5757 3.87213 20.5967 4.03127C20.7466 5.06252 20.7749 7.47189 18.3652 9.88126L12 16.2469Z"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M17.3062 10.9406L17.3062 16.9969C17.3032 17.1947 17.2227 17.3834 17.0811 17.5219L14.0532 20.5594C13.957 20.6553 13.8369 20.7234 13.7051 20.7563C13.5732 20.7893 13.4351 20.7858 13.3052 20.7464C13.1753 20.7069 13.0586 20.6329 12.9673 20.5323C12.876 20.4317 12.814 20.3082 12.7876 20.175L12 16.2469"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M13.0591 6.69373L7.00293 6.69373C6.80518 6.69655 6.6167 6.77742 6.47803 6.91873L3.44043 9.94685C3.34473 10.0429 3.27637 10.1632 3.24365 10.295C3.21045 10.4267 3.21387 10.5649 3.25342 10.6948C3.29297 10.8248 3.36719 10.9415 3.46777 11.0327C3.56836 11.1239 3.69189 11.186 3.82471 11.2125L7.75293 12"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  );
}
