import { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Button, Collapse, Form } from 'antd';
import cx from 'classnames';
import { getChatList } from '@/api/chat';
import { getCompletionList } from '@/api/completion';
import { getBookmarkTemplateList, deleteBookmarkTemplate } from '@/api/template';
import { GlobalContext } from '@/pages/MainLayout';
import Blank from '@/components/global/Blank';
import LoginModal from '@/components/modal/LoginModal';
import ChatMessageBox from '@/components/ChatMessageBox';
import ListItemCard from '@/components/ListItemCard';
import QuickStart from '@/components/QuickStart';
import ArrowIcon from '@/icons/ArrowIcon';
import LoginIcon from '@/icons/LoginIcon';
import Bem from '@/utils/bem';
import './DashboardPage.less';

const bem = new Bem('dashboard-page');
const headerList = ['最近编辑', '模板收藏'];
const { Panel } = Collapse;
const timeout = 3000;
let timer: number;
let blockSwitch = false;

export default function DashboardPage() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { userInfo, defaultProjId } = useContext(GlobalContext);
  const [activeKey, setActiveKey] = useState<Item.Type>('completion');
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(headerList[0]);
  const [bookmarkList, setBookmarkList] = useState<(Item.Template.BookmarkDetail & Item.Template.Detail)[]>([]);
  const [chatList, setChatList] = useState<Item.Chat.Detail[]>([]);
  const [completionList, setCompletionList] = useState<Item.Completion.Detail[]>([]);
  const [bookmarkLoading, setBookmarkLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [completionLoading, setCompletionLoading] = useState(false);

  useEffect(() => {
    setPageDetail({ isDashboard: true });
  }, [setPageDetail]);

  useEffect(() => {
    if (!userInfo) return;

    const isNew = userInfo.is_newly_registered;

    if (!isNew) {
      requestBookmarkList(true);
      requestChatList(true);
      requestCompletionList(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (!!userInfo) return;
    const autoChangeActiveKey = () => {
      timer && window.clearTimeout(timer);
      if (blockSwitch) return;
      setActiveKey(activeKey === 'completion' ? 'chat' : 'completion');
    };

    timer && window.clearTimeout(timer);
    timer = window.setTimeout(autoChangeActiveKey, timeout);

    return () => {
      timer && window.clearTimeout(timer);
    };
  }, [activeKey, userInfo]);

  const requestBookmarkList = async (init?: boolean) => {
    init && setBookmarkLoading(true);
    const res = await getBookmarkTemplateList();
    const list = res.results.map(t => ({ ...t, is_bookmarked: true }));
    setBookmarkList(list);
    init && setBookmarkLoading(false);
  };

  const requestChatList = async (init?: boolean) => {
    init && setChatLoading(true);
    const res = await getChatList('0', { offset: 0, limit: 9999, sortBy: 'last_update_time', queryAll: true });
    const chatList = (res.results || []).map(c => ({ ...c, path: `/projects/${c.project_id}/chat/${c.id}` }));
    setChatList(chatList);
    init && setChatLoading(false);
  };

  const requestCompletionList = async (init?: boolean) => {
    init && setCompletionLoading(true);
    const res = await getCompletionList('0', { offset: 0, limit: 9999, sortBy: 'last_update_time', queryAll: true });
    const completionList = (res.results || []).map(c => ({
      ...c,
      path: `/projects/${c.project_id}/completion/${c.id}`
    }));
    setCompletionList(completionList);
    init && setCompletionLoading(false);
  };

  const onItemUpdate = (type: 'rename' | 'delete' | 'bookmark', itemType: Item.Type, id: string, data: any) => {
    if (itemType === 'chat') {
      const list = chatList.concat();
      const index = list.findIndex(item => item.id === id);

      if (type === 'rename') {
        list[index] = { ...list[index], ...data };
      } else if (type === 'delete') {
        list.splice(index, 1);
      }

      setChatList(list);
    } else if (itemType === 'completion') {
      const list = completionList.concat();
      const index = list.findIndex(item => item.id === id);

      if (type === 'rename') {
        list[index] = { ...list[index], ...data };
      } else if (type === 'delete') {
        list.splice(index, 1);
      }

      setCompletionList(list);
    }
  };

  const onBookmark = async (id: string) => {
    const index = bookmarkList.findIndex(b => b.template_metadata.name === id);
    if (index < 0) return;
    await deleteBookmarkTemplate(bookmarkList[index].id);
    const list = bookmarkList.concat();
    list.splice(index, 1);
    setBookmarkList(list);
    requestBookmarkList();
  };

  const onMouseEnter = () => {
    blockSwitch = true;
  };

  const onMouseLeave = () => {
    blockSwitch = false;
    timer && window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      setActiveKey(activeKey === 'completion' ? 'chat' : 'completion');
    }, timeout);
  };

  const renderCollapsePanelHeader = (key: Item.Type) => {
    return (
      <div className={bem.e('panel-header')} onClick={() => setActiveKey(key)}>
        <ArrowIcon className={bem.e('panel-icon')} direction={activeKey === 'completion' ? 'up' : 'down'} />
        <div className={bem.e('panel-title')}>{key === 'completion' ? '通过模板创作' : '通过对话创作'}</div>
      </div>
    );
  };

  const renderCollapsePanelContent = (key: Item.Type) => {
    return (
      <div className={bem.e('panel-content', key)}>
        {key === 'completion' ? (
          <ChatMessageBox className={bem.e('msg-box', key)} avatarPosition="left">
            <div className={bem.e('completion-title')}>小红书创作星球</div>
            <Form className={bem.e('completion-form')} layout="vertical" name="completion-form" requiredMark={false}>
              <Form.Item className={bem.e('completion-form-item')} name="创作标题" label="创作标题">
                <div className={bem.e('completion-input')}>上海3天2晚都市之旅</div>
              </Form.Item>
              <Form.Item className={bem.e('completion-form-item')} name="描述内容包含" label="描述内容包含">
                <div className={bem.e('completion-input')}>地标：新天地、东方明珠、南京路步行街</div>
              </Form.Item>
            </Form>
            <Button className={bem.e('completion-btn')} type="primary" onClick={() => setVisible(true)}>
              开始创作
            </Button>
          </ChatMessageBox>
        ) : (
          <>
            <div className={bem.e('chat-message-list')}>
              <ChatMessageBox className={bem.e('msg-box', key)} avatarPosition="right">
                <div className={bem.e('line')}>来！doudou ai！</div>
                <div className={bem.e('line')}>写几句爆点文案，介绍介绍你自己！</div>
              </ChatMessageBox>
              <ChatMessageBox className={bem.e('msg-box', key)} isDoudou>
                <div className={bem.e('line')}>家人们，谁懂啊！</div>
                <div className={bem.e('line')}>堪比一个专业团队的文案生成助手兜兜啊！</div>
                <div className={bem.e('line')}>机智专业是我的底色，但风趣or柔情，活泼or霸道，</div>
                <div className={bem.e('line')}>由你决定!</div>
              </ChatMessageBox>
            </div>
            <div className={bem.e('message-textarea')}>
              <div className={bem.e('chat-input')}>请你以“上海3天2晚都市之旅”为主题撰写一篇小红书文案。</div>
              <Button className={bem.e('chat-btn')} type="primary" onClick={() => setVisible(true)}>
                发送
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderGuestPage = () => {
    return (
      <>
        <div className={bem.e('left')}>
          <div className={bem.e('headline')}>你填我写</div>
          <div className={bem.e('headline')}>三言两语</div>
          <div className={bem.e('headline')}>成就下一个爆款博主</div>
          <Button className={bem.e('login-btn')} type="primary" onClick={() => setVisible(true)}>
            <LoginIcon className={bem.e('login-icon')} />
            <span className={bem.e('login-text')}>手机快速登录</span>
          </Button>
        </div>
        <div className={bem.e('right')} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Collapse className={bem.e('collapse')} accordion activeKey={activeKey} bordered={false}>
            <Panel
              className={bem.e('collapse-panel')}
              header={renderCollapsePanelHeader('completion')}
              key="completion"
              showArrow={false}
            >
              {renderCollapsePanelContent('completion')}
            </Panel>
            <Panel
              className={bem.e('collapse-panel')}
              header={renderCollapsePanelHeader('chat')}
              key="chat"
              showArrow={false}
            >
              {renderCollapsePanelContent('chat')}
            </Panel>
          </Collapse>
        </div>
        <LoginModal visible={visible} setVisible={setVisible} />
      </>
    );
  };

  const renderAuthPage = () => {
    return (
      <>
        <div className={bem.e('header-bar')}>
          {headerList.map(h => (
            <div key={h} className={bem.e('header-item', { selected: selected === h })} onClick={() => setSelected(h)}>
              {h}
            </div>
          ))}
        </div>
        <div className={bem.e('container')}>
          {selected === headerList[0] && (
            <>
              <div className={bem.e('list-wrapper', 'hasTitle')}>
                <div className={bem.e('list-content', 'hasTitle')}>
                  <div className={bem.e('list-title')}>对话</div>
                  <div className={bem.e('items', { empty: chatList.length <= 0 })}>
                    {chatLoading ? (
                      <div className={bem.e('list-loading')} />
                    ) : chatList.length > 0 ? (
                      chatList.map(item => (
                        <ListItemCard
                          key={item.id}
                          className={bem.e('item')}
                          item={item}
                          type="chat"
                          onItemClick={() => item.path && navigate(item.path)}
                          onItemUpdate={onItemUpdate}
                          refreshItemList={requestChatList}
                        />
                      ))
                    ) : (
                      <Blank tip="通过对话" path={`/projects/${defaultProjId}/chat`} />
                    )}
                  </div>
                </div>
              </div>
              <div className={bem.e('list-wrapper', 'hasTitle')}>
                <div className={bem.e('list-content', 'hasTitle')}>
                  <div className={bem.e('list-title')}>模版</div>
                  <div className={bem.e('items', { empty: completionList.length <= 0 })}>
                    {completionLoading ? (
                      <div className={bem.e('list-loading')} />
                    ) : completionList.length > 0 ? (
                      completionList.map(item => (
                        <ListItemCard
                          key={item.id}
                          className={bem.e('item')}
                          item={item}
                          type="completion"
                          onItemClick={() => item.path && navigate(item.path)}
                          onItemUpdate={onItemUpdate}
                          refreshItemList={requestCompletionList}
                        />
                      ))
                    ) : (
                      <Blank tip="选择模板" path="/templates" />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {selected === headerList[1] && (
            <div className={bem.e('list-wrapper', 'untitled')}>
              <div className={bem.e('list-content', 'untitled', { empty: bookmarkList.length <= 0 })}>
                {bookmarkLoading ? (
                  <div className={bem.e('list-loading')} />
                ) : bookmarkList.length > 0 ? (
                  bookmarkList.map(b => (
                    <ListItemCard
                      key={b.template_metadata.name}
                      className={bem.e('item')}
                      item={b}
                      type="template"
                      onItemClick={() =>
                        navigate(`/projects/${defaultProjId}/completion`, {
                          state: { templateId: b.template_metadata.name }
                        })
                      }
                      onItemUpdate={(_type, _itemType, id) => onBookmark(id)}
                      refreshItemList={requestBookmarkList}
                    />
                  ))
                ) : (
                  <Blank tip="选择模板" path="/templates" />
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={cx('doudou-page', bem.b({ isAuth: !!userInfo }))}>
      {userInfo ? userInfo.is_newly_registered ? <QuickStart /> : renderAuthPage() : renderGuestPage()}
    </div>
  );
}
