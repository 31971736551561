import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { Input, InputRef } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { updateChat } from '@/api/chat';
import { updateCompletion } from '@/api/completion';
import { GlobalContext } from '@/pages/MainLayout';
import DetailMenu from '@/components/DetailMenu';
import DateIcon from '@/icons/DateIcon';
import EllipsisIcon from '@/icons/EllipsisIcon';
import StarIcon from '@/icons/StarIcon';
import Bem from '@/utils/bem';
import { DATE_FORMATTER, formatDate } from '@/utils/time';
import './ListItemCard.less';

interface Props {
  item: Item.Chat.Detail | Item.Completion.Detail | Item.Template.Detail;
  type: Item.Type;
  className?: string;
  onItemClick: () => void;
  onItemUpdate: (type: 'rename' | 'delete' | 'bookmark', itemType: Item.Type, id: string, data: any) => void;
  refreshItemList?: () => void;
}

const bem = new Bem('list-item-card');

export default function ListItemCard({ item, type, className, onItemClick, onItemUpdate, refreshItemList }: Props) {
  const { projectList } = useContext(GlobalContext);
  const inputRef = useRef<InputRef>(null);
  const [input, setInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (type === 'chat') setName((item as Item.Chat.Detail).name);
    if (type === 'completion') setName((item as Item.Completion.Detail).name);
  }, [item, type]);

  useEffect(() => {
    if (!input || !inputRef || !inputRef.current) return;
    inputRef.current.focus();
  }, [input]);

  const onChatRename = async () => {
    const chatItem = item as Item.Chat.Detail;
    setInput(false);
    if (name === '' || name === chatItem.name) return;
    setLoading(true);

    try {
      await updateChat(chatItem.project_id, chatItem.id, { operation: 'UPDATE_NAME', name });
      onItemUpdate('rename', 'chat', chatItem.id, { name });
    } finally {
      setLoading(false);
    }
  };

  const onCompletionRename = async () => {
    const completionItem = item as Item.Completion.Detail;
    setInput(false);
    if (name === '' || name === completionItem.name) return;
    setLoading(true);

    try {
      await updateCompletion(completionItem.project_id, completionItem.id, { operation: 'UPDATE_NAME', name });
      onItemUpdate('rename', 'completion', completionItem.id, { name });
    } finally {
      setLoading(false);
    }
  };

  const onChatDelete = () => {
    const chatItem = item as Item.Chat.Detail;
    onItemUpdate('delete', 'chat', chatItem.id, {});
  };

  const onCompletionDelete = () => {
    const completionItem = item as Item.Completion.Detail;
    onItemUpdate('delete', 'completion', completionItem.id, {});
  };

  const onTemplateBookmark = (e: MouseEvent, id: string, isBookmarked: boolean) => {
    e.stopPropagation();
    onItemUpdate('bookmark', 'template', id, { isBookmarked });
  };

  const onDefaultClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const renderChatCard = () => {
    const chatItem = item as Item.Chat.Detail;
    return (
      <>
        <div className={bem.e('header', type)}>
          <div className={bem.e('headline')}>
            {input ? (
              <Input
                ref={inputRef}
                className={bem.e('title-input')}
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={onChatRename}
                onPressEnter={onChatRename}
                onClick={onDefaultClick}
              />
            ) : (
              <div className={bem.e('title')}>
                {loading && <LoadingOutlined className={bem.e('title-loading')} />}
                {name || 'Untitled'}
              </div>
            )}
            <DetailMenu
              id={chatItem.id}
              curProjectId={chatItem.project_id}
              projectList={projectList}
              type="chat"
              onRefresh={refreshItemList}
              onRename={() => {
                setName(chatItem.name);
                setInput(true);
              }}
              onDelete={onChatDelete}
              onOpenChange={setMenuOpen}
            >
              <div className={bem.e('dropdown-icon', { hover: menuOpen })} onClick={onDefaultClick}>
                <EllipsisIcon />
              </div>
            </DetailMenu>
          </div>
        </div>
        <div className={bem.e('content', type)}>
          <div
            className={bem.e('desc', type)}
            dangerouslySetInnerHTML={{ __html: chatItem.description || '暂无简介' }}
          />
        </div>
        <div className={bem.e('footer', type)}>
          <div className={bem.e('date')}>
            <DateIcon className={bem.e('date-icon')} />
            <span className={bem.e('date-text')}>{formatDate(chatItem.last_update_time, DATE_FORMATTER)}</span>
          </div>
        </div>
      </>
    );
  };

  const renderCompletionCard = () => {
    const completionItem = item as Item.Completion.Detail;
    return (
      <>
        <div className={bem.e('header', type)}>
          <img
            className={bem.e('temp-img')}
            src={completionItem.template_metadata?.icon_url || 'https://placehold.co/40x40.png'}
            alt="DouDou Template Logo"
          />
          <div className={bem.e('temp-name')}>{completionItem.template_metadata?.title || ''}</div>
        </div>
        <div className={bem.e('content', type)}>
          <div className={bem.e('headline')}>
            {input ? (
              <Input
                ref={inputRef}
                className={bem.e('title-input')}
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={onCompletionRename}
                onPressEnter={onCompletionRename}
                onClick={onDefaultClick}
              />
            ) : (
              <div className={bem.e('title')}>
                {loading && <LoadingOutlined className={bem.e('title-loading')} />}
                {name || 'Untitled'}
              </div>
            )}
            <DetailMenu
              id={completionItem.id}
              curProjectId={completionItem.project_id}
              projectList={projectList}
              type="completion"
              onRefresh={refreshItemList}
              onRename={() => {
                setName(completionItem.name);
                setInput(true);
              }}
              onDelete={onCompletionDelete}
              onOpenChange={setMenuOpen}
            >
              <div className={bem.e('dropdown-icon', { hover: menuOpen })} onClick={onDefaultClick}>
                <EllipsisIcon />
              </div>
            </DetailMenu>
          </div>
          <div
            className={bem.e('desc', type)}
            dangerouslySetInnerHTML={{ __html: completionItem.description || '暂无简介' }}
          />
        </div>
        <div className={bem.e('footer', type)}>
          <div className={bem.e('date')}>
            <DateIcon className={bem.e('date-icon')} />
            <span className={bem.e('date-text')}>{formatDate(completionItem.last_update_time, DATE_FORMATTER)}</span>
          </div>
        </div>
      </>
    );
  };

  const renderTemplateCard = () => {
    const templateItem = item as Item.Template.Detail;
    return (
      <>
        <div className={bem.e('header', type)}>
          <img className={bem.e('temp-img')} src={templateItem.template_metadata.icon_url} alt="DouDou Template Logo" />
        </div>
        <div className={bem.e('content', type)}>
          <div className={bem.e('title', type)}>{templateItem.template_metadata.title}</div>
          <div className={bem.e('desc', type)}>{templateItem.template_metadata.description}</div>
        </div>
        <div className={bem.e('footer', type)}>
          <div
            className={bem.e('bookmark-icon')}
            onClick={e => onTemplateBookmark(e, templateItem.template_metadata.name, templateItem.is_bookmarked)}
          >
            <StarIcon bookmarked={templateItem.is_bookmarked} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={cx(bem.b(), className)} onClick={onItemClick}>
      {type === 'chat' && renderChatCard()}
      {type === 'completion' && renderCompletionCard()}
      {type === 'template' && renderTemplateCard()}
    </div>
  );
}
