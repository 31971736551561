import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';
import { Button } from 'antd';
import cx from 'classnames';
import Showdown from 'showdown';
import { updateChat } from '@/api/chat';
import { updateCompletion } from '@/api/completion';
import DuplicateIcon from '@/icons/DuplicateIcon';
import LikeIcon from '@/icons/LikeIcon';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';
import './ChatMessageBox.less';

interface Props {
  itemType?: Item.Type;
  chatMessage?: Item.Chat.Message;
  completionMessage?: Item.Completion.Message;
  avatar?: string;
  avatarPosition?: 'left' | 'right';
  children?: string | ReactNode;
  className?: string;
  inner?: string;
  isDoudou?: boolean;
  loading?: boolean;
  regenerate?: boolean;
  onMsgRegenerate?: (regenerateId: string) => void;
  onFeedbackCb?: (messageId: string, feedback: Api.Feedback) => void;
}

const bem = new Bem('chat-message-box');
const converter = new Showdown.Converter();

export default function ChatMessageBox({
  itemType,
  chatMessage,
  completionMessage,
  avatar,
  avatarPosition,
  className,
  children,
  inner,
  isDoudou,
  loading,
  regenerate,
  onMsgRegenerate,
  onFeedbackCb
}: Props) {
  const [state, copyToClipboard] = useCopyToClipboard();

  const { projectId, chatId, completionId } = useParams();
  const [likeStatus, setLikeStatue] = useState<Api.Feedback>('NONE');

  const position = useMemo(() => avatarPosition || 'left', [avatarPosition]);
  const innerHtml = useMemo(() => {
    converter.setOption('tables', true);
    return converter.makeHtml(inner || '');
  }, [inner]);

  useEffect(() => {
    if (!chatMessage) return;
    setLikeStatue(chatMessage.feedback);
  }, [chatMessage]);

  useEffect(() => {
    if (!completionMessage) return;
    setLikeStatue(completionMessage.feedback);
  }, [completionMessage]);

  useEffect(() => {
    if (!state.value) return;
    if (state.error) {
      showGlobalMessage('复制失败', 'error');
    } else {
      showGlobalMessage('已复制到剪贴板', 'success');
    }
  }, [state]);

  const onFeedback = async (status: Api.Feedback) => {
    if (!projectId) return;

    const feedback = likeStatus === status ? 'NONE' : status;

    try {
      if (itemType === 'chat') {
        if (!chatMessage || !chatId) return;
        await updateChat(projectId, chatId, {
          operation: 'AI_MESSAGE_FEEDBACK',
          message_id: chatMessage.id,
          feedback
        });
      } else if (itemType === 'completion') {
        if (!completionMessage || !completionId) return;
        await updateCompletion(projectId, completionId, {
          operation: 'COMPLETION_RESPONSE_FEEDBACK',
          completion_response_id: completionMessage.id,
          feedback
        });
      }
    } finally {
      if (itemType === 'chat') {
        if (!chatMessage) return;
        onFeedbackCb?.(chatMessage.id, feedback);
      } else if (itemType === 'completion') {
        if (!completionMessage) return;
        onFeedbackCb?.(completionMessage.id, feedback);
      }
    }
  };

  return (
    <div className={cx(bem.b(position), className)}>
      <div
        style={{ backgroundImage: avatar ? `url(${avatar})` : '' }}
        className={bem.e('avatar', { isDoudou: !!isDoudou })}
      >
        {isDoudou ? 'dou' : avatar ? '' : '我'}
      </div>
      {children ? (
        <div className={bem.e('box', position, { ai: !!isDoudou })}>{children}</div>
      ) : (
        <div className={bem.e('box', position, { ai: !!isDoudou })}>
          <div className={bem.e('chat-msg')} dangerouslySetInnerHTML={{ __html: innerHtml }} />
          {isDoudou && loading && (
            <div className={bem.e('typing')}>
              doudou ai 正在输入
              <div className={bem.e('loading')} />
            </div>
          )}
          {isDoudou && !loading && (
            <div className={bem.e('footer')}>
              {regenerate ? (
                <Button
                  className={bem.e('regenerate-btn')}
                  type="link"
                  onClick={() => onMsgRegenerate?.(chatMessage?.id || '')}
                >
                  重新生成
                </Button>
              ) : (
                <div />
              )}
              {inner && (
                <div className={bem.e('icon-tools')}>
                  <div className={bem.e('like-btn')} onClick={() => onFeedback('LIKE')}>
                    <LikeIcon active={likeStatus === 'LIKE'} like />
                  </div>
                  <div className={bem.e('dislike-btn')} onClick={() => onFeedback('DISLIKE')}>
                    <LikeIcon active={likeStatus === 'DISLIKE'} dislike />
                  </div>
                  <div className={bem.e('duplicate-btn')} onClick={() => copyToClipboard(inner)}>
                    <DuplicateIcon />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
