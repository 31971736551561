import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, Input, Select, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload';
import { uploadImages } from '@/api/common';
import Spinner from '@/components/global/Spinner';
import ChatMessageBox from '@/components/ChatMessageBox';
import ArrowIcon from '@/icons/ArrowIcon';
import ClearIcon from '@/icons/ClearIcon';
import CloseIcon from '@/icons/CloseIcon';
import UploadIcon from '@/icons/UploadIcon';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';
import './TemplateForm.less';

interface Props {
  completionId: string;
  resProgress: boolean;
  template: Item.Template.Metadata | undefined;
  templateInput: string[];
  btnAnimation?: boolean;
  setTemplateInput: (input: string[]) => void;
  onGenerate: () => Promise<void>;
}

const bem = new Bem('template-form');
const { TextArea } = Input;
const { Option } = Select;
const { Dragger, LIST_IGNORE } = Upload;

export default function TemplateForm({
  completionId,
  resProgress,
  template,
  templateInput,
  btnAnimation,
  setTemplateInput,
  onGenerate
}: Props) {
  const [form] = Form.useForm();
  const ref = useRef<{ imageList: UploadFile[] }>({ imageList: [] });
  const [templateNameList, setTemplateNameList] = useState<string[]>([]);
  const [imageList, setImageList] = useState<UploadFile[]>([]);

  const uploading = useMemo(() => imageList.some(i => i.status === 'uploading'), [imageList]);

  const updateImageList = useCallback(
    (list: UploadFile[], updateInput: boolean) => {
      ref.current.imageList = list;
      setImageList(list);
      if (!updateInput) return;
      const index = template?.line_template_list?.findIndex(l => l.type === 'images');
      if (!index || index < 0) return;
      const str = list.reduce((pre, cur) => {
        let res = !!pre ? `${pre}~~~` : '';

        if (cur.status === 'done') {
          res = `${res}${cur.url}`;
        } else if (cur.status === 'uploading') {
          res = `${res}uploading`;
        }

        return res;
      }, '');
      const input = [...templateInput];
      input[index] = str;
      setTemplateInput(input);
    },
    [template, templateInput, setTemplateInput]
  );

  const initFormField = useCallback(
    (template: Item.Template.Metadata, input: string[]) => {
      const { line_template_list } = template;
      if (!line_template_list || line_template_list.length <= 0) return;
      const templateNameList = line_template_list.map(line => line.display_name);

      templateNameList.forEach((n, i) => {
        let val;

        if (line_template_list[i].type === 'insert') {
          val = input[i] || '';
        } else if (line_template_list[i].type === 'select') {
          val = input[i] || line_template_list[i].select_default_value || '';
        } else if (line_template_list[i].type === 'textarea') {
          val = input[i] || '';
        } else if (line_template_list[i].type === 'images') {
          val = input[i] || '';
          const list = val
            .split('~~~')
            .filter(v => !!v)
            .map(v => {
              if (v === 'uploading') {
                return { uid: 'uploading', name: '', url: '', thumbUrl: '', percent: 0, status: 'uploading' };
              }

              return { uid: v, name: v, url: v, thumbUrl: v, percent: 100, status: 'done' };
            });
          updateImageList(list, false);
        }

        form.setFieldValue(n, val);
      });

      setTemplateNameList(templateNameList);
    },
    [form, updateImageList]
  );

  useEffect(() => {
    if (!template) return;
    initFormField(template, templateInput);
  }, [template, templateInput, initFormField]);

  const onFieldValChange = (index: number, val: string) => {
    const input = [...templateInput];
    input[index] = val;
    setTemplateInput(input);
  };

  const beforeImageUpload = (file: RcFile) => {
    const { name, size } = file;

    if (size > 10 * 1024 * 1024) {
      showGlobalMessage(`图片 ${name} 大小超出 10M 限制`, 'error');
      return LIST_IGNORE;
    }

    const imageType = ['png', 'jpg', 'jpeg'].map(item => item.toUpperCase());
    const format = name.match(/\.(\w+)$/);
    const imageFormat = format ? format[1].toUpperCase() : '';

    if (!imageType.includes(imageFormat)) {
      showGlobalMessage(`仅支持上传 ${imageType.join('、')} 格式的文件`, 'error');
      return LIST_IGNORE;
    }

    return true;
  };

  const customImageRequest = async (opt: any) => {
    const { file } = opt;
    updateImageList(
      [
        ...ref.current.imageList,
        { uid: 'uploading', name: '', url: '', thumbUrl: '', percent: 0, status: 'uploading' }
      ],
      true
    );
    const formData = new FormData();
    formData.append('images', file);

    try {
      const res = await uploadImages(formData);
      const url = res.urls[0];
      const list: UploadFile[] = [...ref.current.imageList];
      const uploadImage = list.find(i => i.uid === 'uploading');

      if (uploadImage) {
        uploadImage.uid = url;
        uploadImage.name = url;
        uploadImage.url = url;
        uploadImage.thumbUrl = url;
        uploadImage.percent = 100;
        uploadImage.status = 'done';
      } else {
        list.push({ uid: url, name: url, url, thumbUrl: url, percent: 100, status: 'done' });
      }

      updateImageList(list, true);
    } catch (err) {
      const list: UploadFile[] = [...ref.current.imageList];
      const index = list.findIndex(i => i.uid === 'uploading');
      if (index < 0) return;
      showGlobalMessage(`图片 ${file.name} 上传失败`, 'error');
      list.splice(index, 1);
      updateImageList(list, true);
    }
  };

  const onUploadRemove = (id: string) => {
    const list = imageList.filter(i => i.uid !== id);
    updateImageList(list, true);
  };

  const onClearInput = () => {
    const inputArr = [];
    setTemplateInput(inputArr);
  };

  const onSend = async () => {
    try {
      await form.validateFields(templateNameList);
      onGenerate();
    } catch (err) {
      console.log('form error', err);
    }
  };

  const renderLineField = (line: Item.Template.Line, index: number) => {
    const { type, display_name, min_length, max_length, options, insert_default_value } = line;

    if (type === 'insert') {
      return (
        <Input
          className={bem.e('template-input')}
          autoComplete="off"
          placeholder={insert_default_value || display_name}
          minLength={min_length}
          maxLength={max_length}
          onChange={e => onFieldValChange(index, e.target.value)}
        />
      );
    } else if (type === 'select') {
      return (
        <Select
          className={bem.e('template-select')}
          popupClassName={bem.e('template-select-menu')}
          suffixIcon={<ArrowIcon className={bem.e('select-icon')} direction="down" />}
          onChange={val => onFieldValChange(index, val)}
        >
          {(options || []).map(op => (
            <Option key={op} value={op}>
              {op}
            </Option>
          ))}
        </Select>
      );
    } else if (type === 'textarea') {
      return (
        <TextArea
          style={{ resize: 'none' }}
          className={bem.e('template-textarea')}
          placeholder={insert_default_value || display_name}
          onChange={e => onFieldValChange(index, e.target.value)}
        />
      );
    } else if (type === 'images') {
      return (
        <>
          <Dragger
            className={bem.e('template-dragger')}
            accept="image/png, image/jpeg"
            disabled={uploading}
            multiple
            showUploadList={false}
            fileList={imageList}
            beforeUpload={beforeImageUpload}
            customRequest={customImageRequest}
          >
            <UploadIcon />
            <div className={bem.e('template-upload-text', { disabled: uploading })}>拖拽图片到此或点击上传</div>
            <Button className={bem.e('upload-btn')} type="primary" disabled={uploading} loading={uploading}>
              上传图片
            </Button>
          </Dragger>
          {imageList.length > 0 && (
            <div className={bem.e('upload-image-list')}>
              {imageList.map((image, index) => (
                <div key={`${image.uid}-${index}`} className={bem.e('upload-image-item')}>
                  {image.status === 'uploading' && <Spinner className={bem.e('upload-spinner')} />}
                  {image.status === 'done' && (
                    <>
                      <div style={{ backgroundImage: `url(${image.url})` }} className={bem.e('upload-image')} />
                      <div className={bem.e('upload-image-icon')} onClick={() => onUploadRemove(image.uid)}>
                        <CloseIcon />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const renderTemplateContent = () => {
    if (!template) return null;
    const { title, line_template_list } = template;
    if (!line_template_list) return null;
    return (
      <>
        <div className={bem.e('template-title')}>{title}</div>
        <Form form={form} layout="vertical" name="template-form" requiredMark={false}>
          {line_template_list.map((line, index) => {
            const { index_number, display_name, is_required } = line;
            return (
              <Form.Item
                key={index_number}
                className={bem.e('template-form-item')}
                name={display_name}
                label={display_name}
                rules={[{ required: is_required, message: `${display_name}为必填字段，不能为空` }]}
              >
                {renderLineField(line, index)}
              </Form.Item>
            );
          })}
        </Form>
      </>
    );
  };

  return (
    <ChatMessageBox avatarPosition="left" className={bem.e('msg-box')}>
      <div className={bem.e('template-wrapper')}>
        <div className={bem.e('template-content')}>{renderTemplateContent()}</div>
        <div className={bem.e('template-footer')}>
          <Button className={bem.e('clear-btn')} onClick={onClearInput}>
            <ClearIcon />
          </Button>
          <Button
            className={bem.e('generate-btn', { animation: !!btnAnimation })}
            type="primary"
            disabled={resProgress || uploading}
            loading={resProgress}
            onClick={onSend}
          >
            {completionId ? '重新生成' : '开始创作'}
          </Button>
        </div>
      </div>
    </ChatMessageBox>
  );
}
