import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('close-icon');

export default function CloseIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M19.7769 4.21942C20.0698 4.51233 20.0698 4.98718 19.7769 5.28009L5.28125 19.7758C4.98828 20.0687 4.51367 20.0687 4.2207 19.7758C3.92773 19.4829 3.92773 19.008 4.2207 18.7151L10.9385 11.9971L4.2207 5.28009C3.92773 4.98718 3.92773 4.51233 4.2207 4.21942C4.51367 3.92651 4.98828 3.92651 5.28125 4.21942L11.999 10.9366L18.7163 4.21942C19.0093 3.92651 19.4839 3.92651 19.7769 4.21942ZM19.7769 19.7753C20.0698 19.4825 20.0698 19.0076 19.7769 18.7147L13.7666 12.7043L12.7061 13.7648L18.7163 19.7753C19.0093 20.0682 19.4839 20.0682 19.7769 19.7753Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
