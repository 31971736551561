import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('blank-icon');

export default function BlankIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.8722 1C17.6173 1 18.2738 1.17786 18.9121 1.51221L18.9537 1.5343C19.5745 1.86633 20.0681 2.34607 20.4148 2.9541L20.4657 3.04626C20.8143 3.69812 21 4.34473 21 6.12781L21 17.8722L20.999 18.0938C20.9843 19.6744 20.8076 20.3015 20.4878 20.9121L20.4657 20.9537C20.1337 21.5745 19.6539 22.0681 19.0459 22.4148L18.9537 22.4657C18.3019 22.8143 17.6553 23 15.8722 23L8.12781 23L7.90625 22.999C6.32556 22.9843 5.69849 22.8076 5.08789 22.4878L5.04626 22.4657C4.42554 22.1337 3.93188 21.6539 3.58521 21.0459L3.5343 20.9537C3.18567 20.3019 3 19.6553 3 17.8722L3 6.12781C3 4.38269 3.17786 3.7262 3.51221 3.08789L3.5343 3.04626C3.86633 2.42554 4.34607 1.93188 4.9541 1.58521L5.04626 1.5343C5.69812 1.18567 6.34473 1 8.12781 1L15.8722 1ZM16.0753 2.50085L8.02454 2.50037L7.823 2.50183C6.65503 2.51538 6.20984 2.61328 5.75366 2.85718C5.36328 3.06604 5.0658 3.3634 4.85693 3.75391C4.5929 4.24768 4.5 4.72876 4.5 6.12793L4.50012 17.9757L4.50171 18.1771C4.51514 19.3451 4.61304 19.7904 4.85693 20.2465C5.0658 20.637 5.36328 20.9343 5.75366 21.1432C6.22278 21.394 6.68042 21.4905 7.92468 21.4995L8.12781 21.5002L15.8722 21.5002C17.2714 21.5002 17.7524 21.4072 18.2463 21.1432C18.6367 20.9343 18.9342 20.637 19.1431 20.2465C19.3939 19.7773 19.4902 19.3197 19.4993 18.0756L19.5 17.8724L19.5 6.12793C19.5 4.72876 19.4071 4.24768 19.1431 3.75391C18.9342 3.3634 18.6367 3.06604 18.2463 2.85718C17.7772 2.60632 17.3196 2.50989 16.0753 2.50085ZM17.25 7C17.25 6.58582 16.9142 6.25 16.5 6.25L7.5 6.25C7.08582 6.25 6.75 6.58582 6.75 7C6.75 7.41418 7.08582 7.75 7.5 7.75L16.5 7.75C16.9142 7.75 17.25 7.41418 17.25 7ZM16.5 11.25C16.9142 11.25 17.25 11.5858 17.25 12C17.25 12.4142 16.9142 12.75 16.5 12.75L7.5 12.75C7.08582 12.75 6.75 12.4142 6.75 12C6.75 11.5858 7.08582 11.25 7.5 11.25L16.5 11.25ZM12.75 17C12.75 16.5858 12.4142 16.25 12 16.25L7.5 16.25C7.08582 16.25 6.75 16.5858 6.75 17C6.75 17.4142 7.08582 17.75 7.5 17.75L12 17.75C12.4142 17.75 12.75 17.4142 12.75 17Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="#474747"
        fillOpacity="1"
      />
    </svg>
  );
}
