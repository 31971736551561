import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import cx from 'classnames';
import { getChatList } from '@/api/chat';
import { getCompletionList } from '@/api/completion';
import Blank from '@/components/global/Blank';
import ListItemCard from '@/components/ListItemCard';
import Bem from '@/utils/bem';
import './ProjectDetail.less';

const bem = new Bem('project-detail-page');

export default function ProjectDetail() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const [chatLoading, setChatLoading] = useState(true);
  const [completionLoading, setCompletionLoading] = useState(true);
  const [chatList, setChatList] = useState<Item.Chat.Detail[]>([]);
  const [completionList, setCompletionList] = useState<Item.Completion.Detail[]>([]);

  const requestChatList = useCallback(
    async (init?: boolean) => {
      const res = await getChatList(projectId || '', { offset: 0, limit: 9999, sortBy: 'create_time' });
      const chatList = (res.results || []).map(c => ({ ...c, path: `/projects/${c.project_id}/chat/${c.id}` }));
      setChatList(chatList);
      init && setChatLoading(false);
    },
    [projectId]
  );

  const requestCompletionList = useCallback(
    async (init?: boolean) => {
      const res = await getCompletionList(projectId || '', { offset: 0, limit: 9999, sortBy: 'create_time' });
      const completionList = (res.results || []).map(c => ({
        ...c,
        path: `/projects/${c.project_id}/completion/${c.id}`
      }));
      setCompletionList(completionList);
      init && setCompletionLoading(false);
    },
    [projectId]
  );

  useEffect(() => {
    setPageDetail({ isProjectDetail: true });
  }, [setPageDetail]);

  useEffect(() => {
    requestChatList(true);
    requestCompletionList(true);
  }, [requestChatList, requestCompletionList]);

  const onItemUpdate = (type: 'rename' | 'delete' | 'bookmark', itemType: Item.Type, id: string, data: any) => {
    if (itemType === 'chat') {
      const list = chatList.concat();
      const index = list.findIndex(item => item.id === id);

      if (type === 'rename') {
        list[index] = { ...list[index], ...data };
      } else if (type === 'delete') {
        list.splice(index, 1);
      }

      setChatList(list);
    } else if (itemType === 'completion') {
      const list = completionList.concat();
      const index = list.findIndex(item => item.id === id);

      if (type === 'rename') {
        list[index] = { ...list[index], ...data };
      } else if (type === 'delete') {
        list.splice(index, 1);
      }

      setCompletionList(list);
    }
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      <div className={bem.e('list-wrapper')}>
        <div className={bem.e('list-container')}>
          <div className={bem.e('list-title')}>对话</div>
          <div ref={ref} className={bem.e('items', { empty: chatList.length <= 0 })}>
            {chatLoading ? (
              <div className={bem.e('list-loading')} />
            ) : chatList.length > 0 ? (
              chatList.map(item => (
                <ListItemCard
                  key={item.id}
                  className={bem.e('item')}
                  item={item}
                  type="chat"
                  onItemClick={() => item.path && navigate(item.path)}
                  onItemUpdate={onItemUpdate}
                  refreshItemList={requestChatList}
                />
              ))
            ) : (
              <Blank tip="通过对话" path={`/projects/${projectId}/chat`} />
            )}
          </div>
        </div>
      </div>
      <div className={bem.e('list-wrapper')}>
        <div className={bem.e('list-container')}>
          <div className={bem.e('list-title')}>模版</div>
          <div className={bem.e('items', { empty: completionList.length <= 0 })}>
            {completionLoading ? (
              <div className={bem.e('list-loading')} />
            ) : completionList.length > 0 ? (
              completionList.map(item => (
                <ListItemCard
                  key={item.id}
                  className={bem.e('item')}
                  item={item}
                  type="completion"
                  onItemClick={() => item.path && navigate(item.path)}
                  onItemUpdate={onItemUpdate}
                  refreshItemList={requestCompletionList}
                />
              ))
            ) : (
              <Blank tip="选择模板" path="/templates" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
