import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('clear-icon');

export default function ClearIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15.2491 5.17334C15.2084 3.41382 13.7693 2 12 2C10.2051 2 8.75 3.45508 8.75 5.25L2.75 5.25L2.70593 5.25122C2.31226 5.27405 2 5.60059 2 6C2 6.41418 2.33582 6.75 2.75 6.75L9.5 6.75L9.54407 6.74878C9.93774 6.72595 10.25 6.39941 10.25 6L10.25 5.25L10.251 5.18982C10.2827 4.25122 11.0536 3.5 12 3.5C12.9666 3.5 13.75 4.28345 13.75 5.25L13.75 6L13.7512 6.04407C13.774 6.43774 14.1006 6.75 14.5 6.75L21 6.75L21.0441 6.74878C21.4377 6.72595 21.75 6.39941 21.75 6C21.75 5.58582 21.4142 5.25 21 5.25L15.25 5.25L15.2491 5.17334ZM5.62915 7.75L6.72632 18.7239C6.83911 19.8519 7.77222 20.7162 8.89868 20.749L8.96509 20.75L15.0344 20.75C16.1683 20.75 17.1212 19.9071 17.2657 18.7893L17.2733 18.7233L18.1925 9.50269L18.3691 7.75L19.8772 7.75L19.6851 9.65259L18.7659 18.8721C18.5774 20.7621 17.0072 22.2081 15.1168 22.2491L15.0344 22.25L8.96509 22.25C7.06604 22.25 5.47156 20.8315 5.2428 18.955L5.23376 18.8732L4.12109 7.75L5.62915 7.75ZM11 12.75C11 12.3358 10.6642 12 10.25 12C9.83582 12 9.5 12.3358 9.5 12.75L9.5 18.75C9.5 19.1642 9.83582 19.5 10.25 19.5C10.6642 19.5 11 19.1642 11 18.75L11 12.75ZM13.75 12C14.1642 12 14.5 12.3358 14.5 12.75L14.5 18.75C14.5 19.1642 14.1642 19.5 13.75 19.5C13.3358 19.5 13 19.1642 13 18.75L13 12.75C13 12.3358 13.3358 12 13.75 12Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="#2E2E2E"
        fillOpacity="1"
      />
    </svg>
  );
}
