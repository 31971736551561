import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { getProjectList } from '@/api/project';
import { getSubscriptionList } from '@/api/subscription';
import BackgroundImage from '@/components/global/BackgroundImage';
import Header from '@/components/global/Header';
import Sidebar from '@/components/global/Sidebar';
import EllipsisIcon from '@/icons/EllipsisIcon';
import LogoIcon from '@/icons/LogoIcon';
import Bem from '@/utils/bem';
import { setStorage, getStorage, removeStorage } from '@/utils/storage';
import { DOUDOU_USERINFO } from '@/utils/variables';
import './MainLayout.less';

interface Props {
  noHeader?: boolean;
  requiredAuth?: boolean;
}

const bem = new Bem('main-layout');

export const GlobalContext = createContext<
  Page.Context & {
    isAuth: boolean;
    defaultProjId: string;
    nameInfo: Page.NameInfo;
    pageInfo: Page.Info;
    projectList: Page.MenuSubItem[];
    subscriptionInfo: Subscription.Info | undefined;
    userInfo: User.Info | undefined;
    updateNameInfo: (name: string, type: Item.Type) => void;
    setUserInfo: (userInfo: User.Info | undefined) => void;
    refreshSubscriptionInfo: () => Promise<void>;
    refreshMenu: () => Promise<void>;
  }
>({} as any);

export default function MainLayout({ noHeader, requiredAuth }: Props) {
  const storageInfo = getStorage<User.Info>(DOUDOU_USERINFO);
  const navigate = useNavigate();
  const { projectId, chatId, completionId } = useParams();
  const ref = useRef<{ userInfo: User.Info | undefined }>({} as any);
  const [subscriptionInfo, setSubscriptionInfo] = useState<Subscription.Info>();
  const [userInfo, setUserInfo] = useState(storageInfo);
  const [defaultProjId, setDefaultProjId] = useState('');
  const [projectList, setProjectList] = useState<Page.MenuSubItem[]>([]);
  const [pageDetail, setPageDetail] = useState<Page.Context>({});
  const [projectName, setProjectName] = useState('');
  const [chatName, setChatName] = useState('');
  const [completionName, setCompletionName] = useState('');

  const isAuth = useMemo(() => !!userInfo, [userInfo]);
  const pageInfo: Page.Info = useMemo(() => ({ projectId, chatId, completionId }), [projectId, chatId, completionId]);
  const nameInfo: Page.NameInfo = useMemo(
    () => ({ projectName, chatName, completionName }),
    [projectName, chatName, completionName]
  );

  useEffect(() => {
    requiredAuth && !userInfo && navigate('/home', { replace: true });
  }, [requiredAuth, userInfo, navigate]);

  useEffect(() => {
    if (userInfo) {
      setStorage(DOUDOU_USERINFO, userInfo);
    } else {
      removeStorage(DOUDOU_USERINFO);
    }

    ref.current.userInfo = userInfo;
  }, [userInfo]);

  useEffect(() => {
    const equal = isEqual(ref.current.userInfo, storageInfo);
    if (!equal) setUserInfo(storageInfo);
  }, [storageInfo]);

  useEffect(() => {
    const project = projectList.find(p => p.id === projectId);
    if (!project) return;
    setProjectName(project.title);
  }, [projectId, projectList]);

  const updateNameInfo = useCallback((name: string, type: Item.Type) => {
    if (type === 'chat') setChatName(name);
    if (type === 'completion') setCompletionName(name);
  }, []);

  const refreshMenu = useCallback(async () => {
    const res = await getProjectList({ offset: 0, limit: 9999 });
    const list = (res.results || []).map(p => ({
      id: p.id,
      title: p.name,
      icon: !p.is_default && <EllipsisIcon />,
      path: `/projects/${p.id}`
    }));
    const defaultId = (res.results || []).find(proj => proj.is_default);
    setDefaultProjId(defaultId?.id || '');
    setProjectList(list);
  }, []);

  const refreshSubscriptionInfo = useCallback(async () => {
    const res = await getSubscriptionList({ offset: 0, limit: 1 });
    const curSubscriptionInfo = res.results[0];
    setSubscriptionInfo(curSubscriptionInfo);
  }, []);

  if (requiredAuth && !userInfo) return null;

  return (
    <GlobalContext.Provider
      value={{
        ...pageDetail,
        isAuth,
        defaultProjId,
        nameInfo,
        pageInfo,
        projectList,
        subscriptionInfo,
        userInfo,
        updateNameInfo,
        setUserInfo,
        refreshSubscriptionInfo,
        refreshMenu
      }}
    >
      <BackgroundImage isAuth={isAuth} />
      {userInfo?.is_newly_registered && pageDetail.isDashboard && <div className={bem.e('mask')} />}
      <div className={bem.b({ isAuth })}>
        <Sidebar className={bem.e('sidebar', { isAuth })} isAuth={isAuth} projectList={projectList} />
        <main id="main" className={bem.e('main', { isAuth })}>
          {isAuth && !noHeader && <Header className={bem.e('header')} />}
          <div
            id="page-content"
            className={bem.e('content', {
              isAuth,
              quickStart: !!userInfo?.is_newly_registered && !!pageDetail.isDashboard
            })}
          >
            <Outlet context={{ setPageDetail }} />
          </div>
        </main>
      </div>
      {!isAuth && (
        <div className={bem.e('footer')}>
          <a className={bem.e('footer-content')} href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            <LogoIcon className={bem.e('footer-icon')} tiny /> 沪ICP备2023025138号
          </a>
        </div>
      )}
    </GlobalContext.Provider>
  );
}
