import { MouseEvent } from 'react';
import { Button } from 'antd';
import ModalContainer from '@/components/modal/ModalContainer';
import Bem from '@/utils/bem';
import './ConfirmModal.less';

interface Props {
  message: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onClose: (confirm: boolean) => void;
}

const bem = new Bem('confirm-modal');

export default function ConfirmModal({ message, visible, setVisible, onClose }: Props) {
  const onClick = (e: MouseEvent, confirm: boolean) => {
    e.stopPropagation();
    onClose(confirm);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <ModalContainer className={bem.b()} disableCloseIcon visible={visible} width={600} setVisible={setVisible}>
        <div className={bem.e('container')}>
          <div className={bem.e('text')}>{message}</div>
          <div className={bem.e('btns')}>
            <Button className={bem.e('btn')} type="primary" onClick={e => onClick(e, true)}>
              确定
            </Button>
            <Button className={bem.e('btn')} onClick={e => onClick(e, false)}>
              取消
            </Button>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
}
