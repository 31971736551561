import { fetchReq } from '@/utils/request';

export function createChat(
  projectId: string,
  params: { user_input: string },
  controller: AbortController
): ReadableStreamDefaultReader<Uint8Array> {
  return fetchReq(`/projects/${projectId}/chats`, 'POST', params, true, controller) as any;
}

export function deleteChat(projectId: string, chatId: string): Promise<void> {
  return fetchReq(`/projects/${projectId}/chats/${chatId}`, 'DELETE', {});
}

export function updateChat(
  projectId: string,
  chatId: string,
  params: {
    operation: Api.UpdateChatOperation;
    clear_all?: boolean;
    description?: string;
    feedback?: Api.Feedback;
    message_id?: string;
    name?: string;
    project_id?: string;
    user_input?: string;
  },
  controller?: AbortController
): ReadableStreamDefaultReader<Uint8Array> {
  return fetchReq(`/projects/${projectId}/chats/${chatId}`, 'PATCH', params, true, controller) as any;
}

export function getChat(
  projectId: string,
  chatId: string,
  messages_page = 1,
  messages_size = 50
): Promise<Item.Chat.Detail> {
  return fetchReq(
    `/projects/${projectId}/chats/${chatId}?messages_size=${messages_size}&messages_page=${messages_page}`,
    'GET',
    {}
  );
}

export function getChatList(
  projectId: string,
  params: { offset: number; limit: number; sortBy: Api.ListSortOption; queryAll?: boolean }
): Promise<{ count: number; results: Item.Chat.Detail[] }> {
  let url = `/projects/${projectId}/chats?offset=${params.offset}&limit=${params.limit}&sort_order=desc&sort_field=${params.sortBy}`;
  if (params.queryAll) url = `${url}&all_projects=True`;
  return fetchReq(url, 'GET', {});
}
