import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  active?: boolean;
  className?: string;
  dislike?: boolean;
  like?: boolean;
}

const bem = new Bem('ellipsis-icon');

export default function EllipsisIcon({ active, className, dislike, like }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      {dislike && (
        <>
          {active ? (
            <path
              d="M17.1133 19.7544C17.3105 20.4229 17.0923 21.1447 16.5579 21.5918C15.8167 22.212 14.7129 22.114 14.0925 21.3727L9.24634 15.5809L9.24951 15.5797L8.34595 14.4988C8.12012 14.2289 7.99634 13.8882 7.99634 13.5361L7.99634 2.58093C7.99634 2.30481 8.22021 2.08093 8.49634 2.08093L17.4626 2.08093C19.2563 2.08093 20.8308 3.27515 21.3145 5.00244L23.1077 11.407C23.1692 11.6263 23.2002 11.8531 23.2004 12.0809C23.2004 13.4617 22.0811 14.5811 20.7004 14.5811L16.2417 14.5806C16.1985 14.5818 16.1553 14.5885 16.1138 14.6008C15.8489 14.679 15.6975 14.957 15.7756 15.2219L15.9551 15.83L17.1133 19.7544ZM6.49878 14.0809C6.7749 14.0809 6.99878 13.8571 6.99878 13.5809L6.99878 2.58093C6.99878 2.30469 6.7749 2.08093 6.49878 2.08093L3.99878 2.08093C2.34204 2.08093 0.998779 3.42407 0.998779 5.08093L0.998779 11.0809C0.998779 12.7378 2.34204 14.0809 3.99878 14.0809L6.49878 14.0809ZM3.50562 6.93262C3.55518 7.29871 3.86914 7.58093 4.24878 7.58093C4.66309 7.58093 4.99878 7.24512 4.99878 6.83093L4.99878 5.33093L4.99194 5.22913C4.94238 4.86304 4.62842 4.58093 4.24878 4.58093C3.83447 4.58093 3.49878 4.91663 3.49878 5.33093L3.49878 6.83093L3.50562 6.93262Z"
              clipRule="evenodd"
              fillRule="evenodd"
              fill="#9FC653"
              fillOpacity="1"
            />
          ) : (
            <path
              d="M15.9053 21.556C16.645 21.0068 16.9751 20.0623 16.7388 19.1719L15.5085 14.5409C15.5051 14.5275 15.5129 14.5138 15.5264 14.5103L15.5327 14.5094L20.0449 14.5094C21.7017 14.5094 23.0449 13.1663 23.0449 11.5094C23.0449 11.236 23.0076 10.9639 22.9338 10.7006L21.3184 4.93091C20.8347 3.20361 19.2603 2.0094 17.4663 2.0094L4.00024 2.0094C2.34351 2.0094 1.00024 3.35254 1.00024 5.0094L1.00024 11.0094C1.00024 12.6663 2.34351 14.0094 4.00024 14.0094L7.50024 14.0094L12.7576 21.0907C13.4983 22.0885 14.9077 22.2968 15.9053 21.556ZM13.9915 20.2338L13.9619 20.1965L8.25488 12.5094L8.25024 12.5096L8.25024 3.50964L17.4663 3.5094C18.5637 3.5094 19.5295 4.22437 19.8535 5.26685L19.8738 5.33533L21.4893 11.105C21.5261 11.2366 21.5449 11.3727 21.5449 11.5094C21.5449 12.319 20.9036 12.9788 20.1011 13.0084L20.0449 13.0094L15.5327 13.0094L15.3975 13.0162C15.3518 13.0194 15.3188 13.0237 15.2737 13.0323L15.1414 13.0605C14.3457 13.2719 13.8647 14.0742 14.0452 14.8704L14.0588 14.9259L15.2891 19.5569C15.3679 19.8538 15.2578 20.1686 15.0112 20.3517C14.6914 20.5891 14.2449 20.5341 13.9915 20.2338ZM4 12.5095L6.75 12.5098L6.75 3.50977L4 3.50952L3.94385 3.51062C3.1416 3.54016 2.5 4.19995 2.5 5.00952L2.5 11.0095L2.50122 11.0658C2.53076 11.8682 3.19043 12.5095 4 12.5095ZM5.5 6.75977C5.5 7.17395 5.16431 7.50977 4.75 7.50977C4.33594 7.50977 4 7.17395 4 6.75977L4 5.25977C4 4.84546 4.33594 4.50977 4.75 4.50977C5.16431 4.50977 5.5 4.84546 5.5 5.25977L5.5 6.75977Z"
              clipRule="evenodd"
              fillRule="evenodd"
              fill="#808080"
              fillOpacity="1"
            />
          )}
        </>
      )}
      {like && (
        <>
          {active ? (
            <path
              d="M17.1135 4.24561C17.3108 3.57715 17.0925 2.85535 16.5581 2.4082C15.8169 1.78796 14.7131 1.88599 14.0928 2.62732L9.24658 8.41907L9.24976 8.42029L8.34619 9.50122C8.12036 9.77112 7.99658 10.1118 7.99658 10.4639L7.99658 21.4191C7.99658 21.6952 8.22046 21.9191 8.49658 21.9191L17.4629 21.9191C19.2566 21.9191 20.8311 20.7249 21.3147 18.9976L23.1079 12.593C23.1694 12.3737 23.2007 12.1469 23.2007 11.9191C23.2007 10.5383 22.0813 9.41895 20.7007 9.41895L16.2419 9.41943C16.1987 9.41821 16.1555 9.4115 16.114 9.39917C15.8491 9.32104 15.6978 9.04297 15.7759 8.77808L15.9553 8.17004L17.1135 4.24561ZM6.49902 9.91907C6.77515 9.91907 6.99902 10.1429 6.99902 10.4191L6.99902 21.4191C6.99902 21.6953 6.77515 21.9191 6.49902 21.9191L3.99902 21.9191C2.34229 21.9191 0.999023 20.5759 0.999023 18.9191L0.999023 12.9191C0.999023 11.2622 2.34229 9.91907 3.99902 9.91907L6.49902 9.91907ZM3.50586 17.0674C3.55542 16.7013 3.86938 16.4191 4.24902 16.4191C4.66333 16.4191 4.99902 16.7549 4.99902 17.1691L4.99902 18.6691L4.99219 18.7709C4.94263 19.137 4.62866 19.4191 4.24902 19.4191C3.83472 19.4191 3.49902 19.0834 3.49902 18.6691L3.49902 17.1691L3.50586 17.0674Z"
              clipRule="evenodd"
              fillRule="evenodd"
              fill="#9FC653"
              fillOpacity="1"
            />
          ) : (
            <path
              d="M15.9055 2.44402C16.6451 2.99319 16.9753 3.93781 16.7388 4.8282L15.5087 9.45921C15.5051 9.47256 15.5131 9.48625 15.5265 9.48979L15.5328 9.49063L20.0449 9.49063C21.7018 9.49063 23.0449 10.8338 23.0449 12.4906C23.0449 12.7641 23.0076 13.0362 22.9338 13.2995L21.3184 19.0692C20.8347 20.7965 19.2603 21.9906 17.4666 21.9906L4.00037 21.9906C2.34351 21.9906 1.00037 20.6475 1.00037 18.9906L1.00037 12.9906C1.00037 11.3338 2.34351 9.99063 4.00037 9.99063L7.50037 9.99063L12.7577 2.90935C13.4984 1.91162 14.9077 1.70328 15.9055 2.44402ZM13.9915 3.76613L13.962 3.80333L8.25488 11.4905L8.25037 11.4903L8.25037 20.4903L17.4666 20.4905C18.5637 20.4905 19.5297 19.7755 19.8538 18.733L19.8739 18.6645L21.4894 12.8949C21.5262 12.7633 21.5449 12.6272 21.5449 12.4905C21.5449 11.6809 20.9036 11.0211 20.1012 10.9915L20.0449 10.9905L15.5328 10.9905L15.3976 10.9837C15.3518 10.9805 15.319 10.9762 15.2739 10.9675L15.1414 10.9393C14.3458 10.728 13.865 9.92561 14.0453 9.12949L14.059 9.07396L15.2891 4.44295C15.3679 4.14615 15.2578 3.83127 15.0114 3.64822C14.6915 3.4108 14.2449 3.46588 13.9915 3.76613ZM4.00049 11.4901L6.75049 11.4899L6.75049 20.4899L4.00049 20.4901L3.94421 20.4891C3.14185 20.4595 2.50049 19.7997 2.50049 18.9901L2.50049 12.9901L2.50146 12.9339C2.53101 12.1315 3.19092 11.4901 4.00049 11.4901ZM5.50049 17.2402C5.50049 16.8259 5.16467 16.4902 4.75049 16.4902C4.3363 16.4902 4.00049 16.8259 4.00049 17.2402L4.00049 18.7402C4.00049 19.1544 4.3363 19.4902 4.75049 19.4902C5.16467 19.4902 5.50049 19.1544 5.50049 18.7402L5.50049 17.2402Z"
              clipRule="evenodd"
              fillRule="evenodd"
              fill="#808080"
              fillOpacity="1"
            />
          )}
        </>
      )}
    </svg>
  );
}
