import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('wechat-icon');

export default function WechatIcon({ className }: Props) {
  return (
    <svg
      className={cx(bem.b(), className)}
      width="22.281250"
      height="19.726807"
      viewBox="0 0 22.2812 19.7268"
      fill="none"
    >
      <path
        d="M3.59814 19.5378C3.74609 18.9561 3.87988 18.3694 4.04834 17.7925C4.25879 17.0735 4.13477 16.5435 3.49951 16.0425C1.39648 14.3782 0.0307617 12.229 0.000488281 9.46411C-0.0327148 6.27271 1.53809 3.91504 4.05957 2.12451C8.56299 -1.07666 15.5303 -0.609619 19.5195 3.18311C19.6885 3.34302 19.835 3.5271 20.064 3.78247C19.6738 3.9812 19.3638 4.15747 19.0391 4.30444C15.9414 5.69409 12.8291 7.0498 9.75195 8.48486C8.81787 8.92114 8.06787 8.91943 7.28809 8.21509C6.83594 7.80615 6.31543 7.46362 5.79004 7.15649C5.58008 7.03369 5.271 7.08374 5.00684 7.05469C4.98633 7.30029 4.87451 7.58472 4.95898 7.78345C5.58154 9.23633 6.22314 10.6826 6.9043 12.1094C7.22754 12.7881 7.70996 12.8689 8.43408 12.4487C12.3657 10.1687 16.2964 7.88525 20.2266 5.60376C20.4766 5.45825 20.7295 5.3208 21.0684 5.13184C21.998 6.70898 22.4326 8.33789 22.2339 10.1348C21.7544 14.4509 17.8911 17.6084 13.582 18.384C11.6621 18.7297 9.72803 18.6992 7.83545 18.1917C7.18457 18.0171 6.65137 18.1077 6.09863 18.4407C5.35889 18.8865 4.60107 19.3003 3.84961 19.7268C3.7666 19.6638 3.68066 19.6008 3.59814 19.5378Z"
        fillRule="nonzero"
        fill="#42B035"
      />
    </svg>
  );
}
