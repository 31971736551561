import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, message } from 'antd';
import cx from 'classnames';
import { createChat, updateChat, getChat } from '@/api/chat';
import { GlobalContext } from '@/pages/MainLayout';
import Blank from '@/components/global/Blank';
import ChatMessageBox from '@/components/ChatMessageBox';
import InputTextArea from '@/components/InputTextArea';
import ClearIcon from '@/icons/ClearIcon';
import CloseIcon from '@/icons/CloseIcon';
import Bem from '@/utils/bem';
import { getDataType, readStreamMessage, showGlobalMessage, throttle } from '@/utils/function';
import { ERROR_CODE } from '@/utils/variables';
import './ChatDetail.less';

const bem = new Bem('chat-detail-page');
let controller: AbortController;
let autoScroll = true;

export default function ChatDetail() {
  const { setPageDetail } = useOutletContext<{ setPageDetail: (detail: Page.Context) => void }>();
  const navigate = useNavigate();
  const { projectId, chatId } = useParams();
  const { updateNameInfo } = useContext(GlobalContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef<{
    chatId?: string;
    newChatId?: string;
    messArr?: Item.Chat.Message[];
    aiMsgId?: string;
    pageNumber?: Number;
  }>({});
  const [newChatId, setNewChatId] = useState('');
  const [chatName, setChatName] = useState('');
  const [messArr, setMessArr] = useState<Item.Chat.Message[]>([]);
  const [aiMsgId, setAiMsgId] = useState('');
  const [disableSend, setDisableSend] = useState(false);
  const [resProgress, setResProgress] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const refreshChat = useCallback(
    async (pageNumber = 1) => {
      if (!projectId || !dataRef.current.chatId || pageNumber <= 0) return;
      setPageLoading(true);

      try {
        const res = await getChat(projectId, dataRef.current.chatId, pageNumber);
        const messages = res.messages.reverse();
        setChatName(res.name);

        if (pageNumber === 1) {
          setMessArr(messages);
        } else {
          setMessArr(messages.concat(dataRef?.current?.messArr || []));
        }

        if (res.messages_has_next) {
          setPageNumber(pageNumber + 1);
        } else {
          setPageNumber(-1);
        }

        setPageLoading(false);
        if (!wrapperRef || !wrapperRef.current || pageNumber !== 1) return;
        const { scrollHeight } = wrapperRef.current;
        wrapperRef.current.scrollTo({ top: scrollHeight, behavior: 'instant' });
      } catch (err) {
        navigate(`/projects/${projectId}`, { replace: true });
      }
    },
    [projectId, navigate]
  );

  const throttleScrollFn = useMemo(
    () =>
      throttle(() => {
        if (wrapperRef.current && wrapperRef.current.scrollTop < 200) {
          refreshChat(dataRef.current.pageNumber);
        }
      }, 1000),
    [refreshChat]
  );

  const onWrapperScroll = useCallback(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const { clientHeight, scrollHeight, scrollTop } = wrapperRef.current;
    autoScroll = scrollHeight - scrollTop <= clientHeight + 60;
    throttleScrollFn();
  }, [throttleScrollFn]);

  useEffect(() => {
    if (!wrapperRef || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    wrapper.addEventListener('scroll', onWrapperScroll, false);

    return () => {
      wrapper.removeEventListener('scroll', onWrapperScroll, false);
    };
  }, [onWrapperScroll]);

  useEffect(() => {
    setPageDetail({ isChatDetail: true });
  }, [setPageDetail]);

  useEffect(() => {
    dataRef.current.chatId = chatId;
    dataRef.current.newChatId = newChatId;
    dataRef.current.messArr = messArr;
    dataRef.current.aiMsgId = aiMsgId;
    dataRef.current.pageNumber = pageNumber;
  }, [chatId, newChatId, messArr, aiMsgId, pageNumber]);

  useEffect(() => {
    updateNameInfo(chatName, 'chat');
  }, [chatName, updateNameInfo]);

  useEffect(() => {
    refreshChat(1);
  }, [refreshChat]);

  const scrollToBottom = () => {
    if (!wrapperRef || !wrapperRef.current) return;
    const { scrollHeight } = wrapperRef.current;
    wrapperRef.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
  };

  const onMsgSend = async (msg: string, regenerateId?: string) => {
    if (!projectId) return;

    let reader;
    controller = new AbortController();

    if (regenerateId) {
      const index = messArr.findIndex(m => m.id === regenerateId);
      const arr = messArr.slice(0, index);
      messArr[index].content = '';
      arr.push(messArr[index]);
      setMessArr(arr);
    } else {
      const arr = [
        ...messArr,
        {
          id: 'usr-temp',
          chat_id: chatId || '',
          content: msg,
          create_time: '',
          last_update_time: '',
          feedback: 'NONE' as Api.Feedback,
          source: 'USR'
        }
      ];
      setMessArr(arr);
    }

    setDisableSend(true);
    setResProgress(true);
    scrollToBottom();

    try {
      if (chatId) {
        if (regenerateId) {
          reader = await updateChat(
            projectId,
            chatId,
            { operation: 'REGENERATE_AI_MESSAGE', message_id: regenerateId },
            controller
          );
        } else {
          reader = await updateChat(
            projectId,
            chatId,
            { operation: 'APPEND_NEW_USER_MESSAGE', user_input: msg },
            controller
          );
        }
      } else {
        reader = await createChat(projectId, { user_input: msg }, controller);
      }

      await readStreamMessage(reader, (done, data) => {
        if (done) {
          setAiMsgId('');
          setDisableSend(false);
          setResProgress(false);

          if (dataRef.current.newChatId) {
            navigate(`/projects/${projectId}/chat/${dataRef.current.newChatId}`);
          }

          return;
        }

        const dataType = getDataType(data);
        const newArr = [...(dataRef.current.messArr || [])];

        if (dataType !== 'String') {
          const { id, user_message_id, ai_message_id } = data;
          if (!id) return;

          if (regenerateId) {
            setAiMsgId(regenerateId);
          } else {
            const userMsg = newArr.find(res => res.id === 'usr-temp');
            if (!userMsg) return;
            setAiMsgId(ai_message_id);
            userMsg.id = user_message_id;
            newArr.push({
              id: ai_message_id,
              chat_id: dataRef.current.chatId || '',
              content: '',
              create_time: '',
              last_update_time: '',
              feedback: 'NONE',
              source: 'AI'
            });
            if (!dataRef.current.chatId) setNewChatId(id);
          }
        } else {
          const aiMsg = newArr.find(res => res.id === dataRef.current.aiMsgId);
          if (!aiMsg) return;
          aiMsg.content = `${aiMsg.content}${data}`;
        }

        setMessArr(newArr);
        autoScroll && scrollToBottom();
      });
    } catch (err) {
      const { code } = err as Api.Error;

      if (ERROR_CODE.subscriptionError.includes(code)) {
        showGlobalMessage(
          <>
            您的用量已耗尽，请&nbsp;
            <Button
              type="link"
              onClick={() => {
                message.destroy();
                navigate('/subscription');
              }}
            >
              订阅
            </Button>
            &nbsp;后继续创作
          </>,
          'error',
          0
        );
      }

      onAbort();
      refreshChat(1);
    }
  };

  const onAbort = () => {
    controller?.abort();
    setAiMsgId('');
    setDisableSend(false);
    setResProgress(false);
  };

  const onClearChat = async () => {
    if (!projectId || !chatId) return;
    await updateChat(projectId, chatId, { operation: 'CLEAR_ALL_MESSAGES', clear_all: true });
    setMessArr([]);
  };

  const onMessageFeedback = (messageId: string, feedback: Api.Feedback) => {
    const newArr = messArr.concat();
    const index = newArr.findIndex(m => m.id === messageId);
    if (index <= 0) return;
    newArr[index] = { ...newArr[index], feedback };
    setMessArr(newArr);
  };

  return (
    <div className={cx('doudou-page', bem.b())}>
      {pageLoading && (
        <div className={bem.e('loading-wrapper')}>
          <div className={bem.e('page-loading')} />
        </div>
      )}
      <div ref={wrapperRef} className={bem.e('chat-wrapper')}>
        <div className={bem.e('content', { empty: messArr.length <= 0 })}>
          {messArr.length > 0 ? (
            messArr.map(msg => {
              const isDoudou = msg.source === 'AI';
              return (
                <ChatMessageBox
                  key={msg.id}
                  itemType="chat"
                  chatMessage={msg}
                  avatarPosition={isDoudou ? 'left' : 'right'}
                  className={bem.e('msg-box')}
                  inner={msg.content}
                  isDoudou={isDoudou}
                  loading={resProgress && aiMsgId === msg.id}
                  regenerate
                  onMsgRegenerate={regenerateId => onMsgSend('', regenerateId)}
                  onFeedbackCb={onMessageFeedback}
                />
              );
            })
          ) : (
            <Blank tip="在对话框内输入文字" />
          )}
        </div>
      </div>
      <InputTextArea className={bem.e('input-area')} disableSend={disableSend} onMsgSend={onMsgSend} />
      {resProgress && (
        <Button className={bem.e('stop-btn')} onClick={onAbort}>
          <CloseIcon className={bem.e('btn-icon')} />
          <span className={bem.e('btn-text')}>停止生成</span>
        </Button>
      )}
      {!resProgress && messArr.length > 0 && (
        <Button className={bem.e('clear-chat-btn')} onClick={onClearChat}>
          <ClearIcon className={bem.e('btn-icon')} />
          <span className={bem.e('btn-text')}>清空对话</span>
        </Button>
      )}
    </div>
  );
}
