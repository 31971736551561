import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('question-icon');

export default function QuestionIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <defs>
        <clipPath id="clip351_1246">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip351_1246)">
        <path
          d="M6.76522 6.21863Q7.36287 4.46667 8.816 3.49402Q10.2691 2.52136 12.1617 2.52136Q13.5797 2.52136 14.6578 3.06042Q15.7418 3.59949 16.3394 4.54871Q16.943 5.49792 16.943 6.67566Q16.943 7.87097 16.3746 8.67957Q15.8121 9.4823 14.6812 10.4667Q13.8902 11.2108 13.4097 11.7382Q12.9293 12.2655 12.607 12.9979Q12.2847 13.7303 12.2847 14.6913Q12.2847 15.3593 12.4547 16.0565L10.7965 16.0565Q10.4859 14.9784 10.4859 14.0643Q10.4859 13.08 10.8082 12.3241Q11.1305 11.5682 11.5875 11.0526Q12.0445 10.537 12.8121 9.81628Q13.7496 8.97839 14.2008 8.34558Q14.6578 7.70691 14.6578 6.83972Q14.6578 5.7616 13.9137 5.11707Q13.1695 4.46667 11.9742 4.46667Q9.52498 4.46667 8.41756 7.17957L6.76522 6.21863ZM11.8277 21.2655Q11.2769 21.2655 10.8785 20.8612Q10.4859 20.451 10.4859 19.9003Q10.4859 19.3202 10.8785 18.9159Q11.2769 18.5057 11.8277 18.5057Q12.4019 18.5057 12.8004 18.9159Q13.1988 19.3202 13.1988 19.9003Q13.1988 20.451 12.8004 20.8612Q12.4019 21.2655 11.8277 21.2655Z"
          fillRule="evenodd"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
}
