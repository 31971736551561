import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Input, Tooltip } from 'antd';
import { sendCode, login } from '@/api/auth';
import { GlobalContext } from '@/pages/MainLayout';
import ModalContainer from '@/components/modal/ModalContainer';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';
import './LoginModal.less';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const bem = new Bem('login-modal');
const countDown = 60;
const phoneReg = /^1\d{10}$/;
const codeReg = /^\d{6}$/;
let timer: number;

export default function LoginModal({ visible, setVisible }: Props) {
  const navigate = useNavigate();
  const { setUserInfo } = useContext(GlobalContext);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [checked, setChecked] = useState(false);
  const [checkedTooltipVisible, setCheckedTooltipVisible] = useState(false);
  const [blockSendSms, setBlockSendSms] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockSendSms <= 0) {
      timer && window.clearTimeout(timer);
    } else {
      timer = window.setTimeout(() => setBlockSendSms(blockSendSms - 1), 1000);
    }
  }, [blockSendSms]);

  const close = useCallback(() => {
    setPhone('');
    setCode('');
    setChecked(false);
    setCheckedTooltipVisible(false);
    setBlockSendSms(0);
  }, []);

  const onSendClick = async () => {
    setBlockSendSms(countDown);

    try {
      const res = await sendCode({ phone_number: phone, operation: 'SIGN_IN_OR_REGISTER_VIA_NUMBER_OTP' });

      if (res.status === 'sent') {
        showGlobalMessage('验证码发送成功', 'success');
      } else {
        showGlobalMessage('验证码发送失败', 'error');
        setBlockSendSms(0);
      }
    } catch (err) {
      setBlockSendSms(0);
    }
  };

  const onLogin = async () => {
    if (!checked) {
      setCheckedTooltipVisible(true);
      return;
    }

    setLoading(true);

    try {
      const res = await login({ otp: code, phone_number: phone });
      setUserInfo(res);
      setVisible(false);
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  };

  const renderCodeInputAfter = () => {
    return (
      <Button
        className={bem.e('send-btn')}
        type="link"
        disabled={!phoneReg.test(phone) || blockSendSms > 0}
        onClick={onSendClick}
      >
        {`${blockSendSms > 0 ? `${blockSendSms}s` : '获取验证码'}`}
      </Button>
    );
  };

  return (
    <ModalContainer
      className={bem.b()}
      customMaskStyle={{ display: 'none' }}
      visible={visible}
      inner
      width=""
      setVisible={setVisible}
      afterClose={close}
    >
      <div className={bem.e('container')}>
        <div className={bem.e('title')}>手机号登录</div>
        <Input.Group size="large">
          <Input
            className={bem.e('phone-input')}
            value={phone}
            addonBefore="+86"
            allowClear
            placeholder="输入手机号"
            onChange={e => setPhone(e.target.value)}
          />
          <Input
            className={bem.e('code-input')}
            value={code}
            addonAfter={renderCodeInputAfter()}
            placeholder="输入验证码"
            onChange={e => setCode(e.target.value)}
          />
        </Input.Group>
        <Button
          className={bem.e('login-btn')}
          block
          type="primary"
          disabled={!phoneReg.test(phone) || !codeReg.test(code)}
          loading={loading}
          onClick={onLogin}
        >
          登录
        </Button>
        <Tooltip
          overlayClassName={bem.e('checkbox-tooltip')}
          title="请阅读并勾选协议"
          open={checkedTooltipVisible}
          placement="topLeft"
        >
          <Checkbox
            className={bem.e('login-checkbox')}
            checked={checked}
            onChange={e => {
              if (e.target.checked && checkedTooltipVisible) setCheckedTooltipVisible(false);
              setChecked(e.target.checked);
            }}
          >
            注册即代表同意
            <a className={bem.e('login-link')} href="/terms.html">
              《用户协议》
            </a>
            、
            <a className={bem.e('login-link')} href="/privacy.html">
              《隐私条款》
            </a>
            <br />
            请勿上传任何敏感及隐私信息
          </Checkbox>
        </Tooltip>
      </div>
    </ModalContainer>
  );
}
