import { ReactNode } from 'react';
import { Dropdown, MenuProps } from 'antd';
import cx from 'classnames';
import Bem from '@/utils/bem';
import './DropdownMenu.less';

interface Props {
  children: ReactNode;
  items: MenuProps['items'];
  overlayClassName: string;
  placement: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | 'top' | 'bottom';
  trigger: ('click' | 'hover' | 'contextMenu')[];
  disabled?: boolean;
  onItemClick: (key: string) => void;
  onOpenChange?: (open: boolean) => void;
}

const bem = new Bem('dropdown-menu');

export default function DropdownMenu({
  children,
  items,
  overlayClassName,
  placement,
  trigger,
  disabled,
  onItemClick,
  onOpenChange
}: Props) {
  const onClick = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    onItemClick(key);
  };

  return (
    <Dropdown
      destroyPopupOnHide
      menu={{ items, onClick }}
      overlayClassName={cx(bem.b(), overlayClassName)}
      placement={placement}
      trigger={disabled ? [] : trigger}
      onOpenChange={onOpenChange}
    >
      {children}
    </Dropdown>
  );
}
