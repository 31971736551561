import { ReactNode, useContext, useMemo, useState } from 'react';
import { MenuProps } from 'antd';
import { deleteChat, updateChat } from '@/api/chat';
import { deleteCompletion, updateCompletion } from '@/api/completion';
import { deleteProject } from '@/api/project';
import { GlobalContext } from '@/pages/MainLayout';
import DropdownMenu from '@/components/global/DropdownMenu';
import ConfirmModal from '@/components/modal/ConfirmModal';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';

interface Props {
  children: ReactNode;
  curProjectId: string;
  id: string;
  projectList: Page.MenuSubItem[];
  type: Item.Type;
  onRefresh?: (projectId: string, itemId: string) => void;
  onRename?: (id: string, type: Item.Type) => void;
  onDelete?: (id: string) => void;
  onOpenChange?: (open: boolean) => void;
}

const bem = new Bem('detail-menu');

export default function DetailMenu({
  children,
  curProjectId,
  id,
  projectList,
  type,
  onRefresh,
  onRename,
  onDelete,
  onOpenChange
}: Props) {
  const { refreshMenu } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);

  const items = useMemo(() => {
    const menu: MenuProps['items'] = [];
    if (type !== 'template') menu.push({ key: 'rename', label: '重命名' });
    if (type !== 'project') {
      const projects = projectList.filter(p => p.id !== curProjectId);
      menu.push({
        key: 'move',
        disabled: projects.length <= 0,
        label: '移动',
        children: projects.map(p => ({ key: p.id, label: p.title }))
      });
    }
    if (type !== 'template') menu.push({ key: 'delete', label: '删除' });
    return menu;
  }, [curProjectId, projectList, type]);

  const onItemClick = async (key: string) => {
    onOpenChange?.(false);

    if (key === 'rename') {
      onRename?.(id, type);
    } else if (key === 'delete') {
      setVisible(true);
    } else if (/^proj.*/.test(key)) {
      try {
        if (type === 'chat') {
          await updateChat(curProjectId, id, { operation: 'MOVE_TO_ANOTHER_PROJECT', project_id: key });
        } else if (type === 'completion') {
          await updateCompletion(curProjectId, id, { operation: 'MOVE_TO_ANOTHER_PROJECT', project_id: key });
        }

        showGlobalMessage('移动成功', 'success');
        onRefresh?.(key, id);
      } catch (err) {
        showGlobalMessage('移动失败', 'error');
      }
    }
  };

  const onClose = async (confirm: boolean) => {
    setVisible(false);
    if (!confirm) return;

    try {
      if (type === 'project') {
        await deleteProject(id);
      } else if (type === 'chat') {
        await deleteChat(curProjectId, id);
      } else if (type === 'completion') {
        await deleteCompletion(curProjectId, id);
      }

      showGlobalMessage('删除成功', 'success');
      onDelete?.(id);

      if (type === 'project') {
        refreshMenu();
      }
    } catch (err) {
      showGlobalMessage('删除失败', 'error');
    }
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      <DropdownMenu
        items={items}
        overlayClassName={bem.b()}
        placement="bottomLeft"
        trigger={['click']}
        onItemClick={onItemClick}
        onOpenChange={onOpenChange}
      >
        {children}
      </DropdownMenu>
      <ConfirmModal
        message={type === 'project' ? '要永久性地删除此文件夹吗？' : '要永久性地删除此文件吗？'}
        visible={visible}
        setVisible={setVisible}
        onClose={onClose}
      />
    </div>
  );
}
