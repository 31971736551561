import { fetchReq } from '@/utils/request';

export function getTemplate(
  templateId: string,
  version?: number
): Promise<Item.Template.Detail & { template: Item.Template.Metadata }> {
  let url = `/templates/${templateId}`;
  if (version !== undefined) url = `${url}?version=${version}`;
  return fetchReq(url, 'GET', {});
}

export function getTemplateList(): Promise<Item.Template.Detail[]> {
  return fetchReq('/templates', 'GET', {});
}

export function getBookmarkTemplate(templateId: string): Promise<Item.Template.BookmarkDetail> {
  return fetchReq(`/bookmarks/templates/${templateId}`, 'GET', {});
}

export function getBookmarkTemplateList(): Promise<{ count: number; results: Item.Template.BookmarkDetail[] }> {
  return fetchReq('/bookmarks/templates', 'GET', {});
}

export function addBookmarkTemplate(params: { template_name: string }): Promise<Item.Template.BookmarkDetail> {
  return fetchReq('/bookmarks/templates', 'POST', params);
}

export function deleteBookmarkTemplate(templateId: string): Promise<void> {
  return fetchReq(`/bookmarks/templates/${templateId}`, 'DELETE', {});
}
