import { fetchReq } from '@/utils/request';

export function sendCode(params: { phone_number: string; operation: Api.OtpOperation }): Promise<{ status: string }> {
  return fetchReq('/auth/otp', 'POST', params);
}

export function login(params: { phone_number: string; otp: string }): Promise<User.Info> {
  return fetchReq('/auth/login', 'POST', params);
}

export function tokenRefresh(params: { refresh: string }): Promise<{ access: string }> {
  return fetchReq('/auth/token_refresh', 'POST', params);
}
