import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createChat } from '@/api/chat';
import { createCompletion } from '@/api/completion';
import { getTemplate } from '@/api/template';
import { GlobalContext } from '@/pages/MainLayout';
import ChatMessageBox from '@/components/ChatMessageBox';
import InputTextArea from '@/components/InputTextArea';
import TemplateForm from '@/components/TemplateForm';
import Bem from '@/utils/bem';
import { getDataType, readStreamMessage } from '@/utils/function';
import { setStorage } from '@/utils/storage';
import { DOUDOU_USERINFO } from '@/utils/variables';
import './QuickStart.less';

const bem = new Bem('quick-start');
const templateName = 'RED_POST';
let controller: AbortController;

export default function QuickStart() {
  const navigate = useNavigate();
  const { defaultProjId, userInfo, setUserInfo } = useContext(GlobalContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef<{ chatId?: string; completionId?: string; chatResponse?: string }>({});
  const [template, setTemplate] = useState<Item.Template.Metadata>();
  const [type, setType] = useState<Item.Type>();
  const [templateInput, setTemplateInput] = useState<string[]>([]);
  const [resProgress, setResProgress] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [chatMsg, setChatMsg] = useState('');
  const [chatResponse, setChatResponse] = useState('');
  const [chatId, setChatId] = useState('');
  const [completionId, setCompletionId] = useState('');

  useEffect(() => {
    dataRef.current.chatId = chatId;
    dataRef.current.completionId = completionId;
    dataRef.current.chatResponse = chatResponse;
  }, [chatId, completionId, chatResponse]);

  useEffect(() => {
    getTemplate(templateName).then(res => {
      const { line_template_list } = res.template;
      if (!line_template_list || line_template_list.length <= 0) return;
      const templateInput = line_template_list.map(line => {
        if (line.type === 'select') return line.select_default_value || '';
        else return '';
      });
      setTemplateInput(templateInput);
      setTemplate(res.template);
    });
  }, []);

  const onComplete = () => {
    if (!userInfo) return;
    const info = { ...userInfo, is_newly_registered: false };
    setStorage(DOUDOU_USERINFO, info);
    setUserInfo(info);
  };

  const onGenerate = async () => {
    if (!template) return;

    let reader;
    controller = new AbortController();
    setResProgress(true);

    try {
      reader = await createCompletion(
        defaultProjId,
        { template_input: templateInput, template_name: template.name, template_version: template.version },
        controller
      );
      await readStreamMessage(reader, (done, data) => {
        if (done) {
          setResProgress(false);
          onComplete();
          navigate(`/projects/${defaultProjId}/completion/${dataRef.current.completionId}`);
          return;
        }

        const dataType = getDataType(data);

        if (dataType !== 'String') {
          const { id } = data;
          if (!id) return;
          if (!dataRef.current.completionId) setCompletionId(id);
        }
      });
    } catch (err) {
      onAbort();
    }
  };

  const onMsgSend = async (msg: string) => {
    let reader;
    controller = new AbortController();
    setDisableSend(true);
    setChatMsg(msg);

    try {
      reader = await createChat(defaultProjId, { user_input: msg }, controller);
      await readStreamMessage(reader, (done, data) => {
        if (done) {
          setDisableSend(false);
          onComplete();
          navigate(`/projects/${defaultProjId}/chat/${dataRef.current.chatId}`);
          return;
        }

        const dataType = getDataType(data);

        if (dataType !== 'String') {
          const { id } = data;
          if (!id) return;
          if (!dataRef.current.chatId) setChatId(id);
        } else {
          const response = `${dataRef.current.chatResponse}${data}`;
          setChatResponse(response);
          if (!wrapperRef || !wrapperRef.current) return;
          const { scrollHeight } = wrapperRef.current;
          wrapperRef.current.scrollTo({ top: scrollHeight, behavior: 'smooth' });
        }
      });
    } catch (err) {
      onAbort();
    }
  };

  const onAbort = () => {
    controller?.abort();
    setResProgress(false);
    setDisableSend(false);
  };

  return (
    <div className={bem.b()}>
      <div className={bem.e('line')} />
      <div
        className={bem.e('left', { hover: !chatId && type === 'completion' })}
        onMouseEnter={() => !chatId && setType('completion')}
      >
        <div className={bem.e('header')}>新手指引：通过模版创作</div>
        <div className={bem.e('container')}>
          <div className={bem.e('wrapper')}>
            <div className={bem.e('content')}>
              <TemplateForm
                completionId=""
                resProgress={resProgress}
                template={template}
                templateInput={templateInput}
                btnAnimation={type === 'completion' && !resProgress}
                setTemplateInput={setTemplateInput}
                onGenerate={onGenerate}
              />
            </div>
          </div>
        </div>
        <div className={bem.e('mask')}>
          <div className={bem.e('tip')}>新手指引：通过模版完成创作</div>
        </div>
      </div>
      <div
        className={bem.e('right', { hover: !completionId && type === 'chat' })}
        onMouseEnter={() => !completionId && setType('chat')}
      >
        <div className={bem.e('header')}>新手指引：通过对话创作</div>
        <div className={bem.e('container')}>
          <div ref={wrapperRef} className={bem.e('wrapper')}>
            <div className={bem.e('content')}>
              <ChatMessageBox className={bem.e('msg-box')} avatarPosition="right">
                Hi! doudou ai!
              </ChatMessageBox>
              <ChatMessageBox className={bem.e('msg-box')} isDoudou>
                我准备好了
              </ChatMessageBox>
              {chatMsg && (
                <>
                  <ChatMessageBox className={bem.e('msg-box')} avatarPosition="right">
                    {chatMsg}
                  </ChatMessageBox>
                  <ChatMessageBox className={bem.e('msg-box')} isDoudou>
                    {chatResponse || 'doudou ai 正在输入...'}
                  </ChatMessageBox>
                </>
              )}
            </div>
          </div>
          <InputTextArea
            className={bem.e('input-area')}
            disableSend={disableSend}
            btnAnimation={type === 'chat' && !disableSend}
            defaultText="兜兜AI能为我提供什么？"
            onMsgSend={onMsgSend}
          />
        </div>
        <div className={bem.e('mask')}>
          <div className={bem.e('tip')}>新手指引：通过对话完成创作</div>
        </div>
      </div>
    </div>
  );
}
