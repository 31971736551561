export function getQueryVariable(variable: string): string {
  const vars = window.location.search.substring(1).split('&');

  for (let item of vars) {
    const pair = item.split('=');

    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return '';
}

export function createPageHistory(pathname: string, nameInfo: Page.NameInfo, pageInfo: Page.Info): Page.Page[] {
  const { projectName, chatName, completionName } = nameInfo;
  const { projectId, chatId, completionId } = pageInfo;
  const pageHistory: Page.Page[] = [];
  const pathArr = pathname.split('/').filter(n => !!n);
  if (pathArr.length <= 0) return pageHistory;
  const lastPath = pathArr[pathArr.length - 1];

  if (['dashboard', 'home'].includes(lastPath)) {
    pageHistory.push({ path: '/dashboard', title: '我的创作' });
  } else if (lastPath === 'templates') {
    pageHistory.push({ path: '/dashboard', title: '我的创作' });
    pageHistory.push({ path: '/templates', title: '选择创作模板' });
  } else if (/^proj.*/.test(lastPath)) {
    pageHistory.push({ path: '/dashboard', title: '我的创作' });
    pageHistory.push({
      itemId: projectId,
      path: `/projects/${projectId}`,
      title: projectName || '',
      type: 'project'
    });
  } else if (/^chat.*/.test(lastPath) || lastPath === 'chat') {
    pageHistory.push({ path: '/dashboard', title: '我的创作' });
    pageHistory.push({
      itemId: projectId,
      path: `/projects/${projectId}`,
      title: projectName || '',
      type: 'project'
    });
    pageHistory.push({
      itemId: chatId,
      path: `/projects/${projectId}/chat${chatId ? `/${chatId}` : ''}`,
      title: chatName || '',
      type: 'chat'
    });
  } else if (/^cmp.*/.test(lastPath) || lastPath === 'completion') {
    pageHistory.push({ path: '/dashboard', title: '我的创作' });
    pageHistory.push({
      itemId: projectId,
      path: `/projects/${projectId}`,
      title: projectName || '',
      type: 'project'
    });
    pageHistory.push({
      itemId: completionId,
      path: `/projects/${projectId}/completion${completionId ? `/${completionId}` : ''}`,
      title: completionName || '',
      type: 'completion'
    });
  } else if (lastPath === 'account') {
    pageHistory.push({ path: '/account', title: '用户中心' });
  } else if (lastPath === 'subscription') {
    pageHistory.push({ path: '/subscription', title: '套餐订阅' });
  }

  return pageHistory;
}
