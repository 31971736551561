import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  direction: 'up' | 'down' | 'left' | 'right';
  className?: string;
}

const bem = new Bem('arrow-icon');

export default function ArrowIcon({ direction, className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="20px" height="20px" viewBox="0 0 40 40">
      <g transform="translate(7, 7)">
        {direction === 'up' && (
          <path
            d="M11.9932,16.99316 C11.7372,16.99316 11.4812,16.89516 11.2862,16.70016 L3.2932,8.70716 C2.9022,8.31616 2.9022,7.68316 3.2932,7.29316 C3.6832,6.90216 4.3162,6.90216 4.7072,7.29316 L11.9942,14.57916 L19.2942,7.29216 C19.6822,6.90316 20.3172,6.90216 20.7082,7.29316 C21.0972,7.68416 21.0972,8.31716 20.7062,8.70716 L12.6992,16.70116 C12.5052,16.89516 12.2492,16.99316 11.9932,16.99316"
            fill="currentColor"
            transform="matrix(1 0 0 -1 0 23.99307)"
            fillRule="evenodd"
          />
        )}
        {direction === 'down' && (
          <path
            d="M11.9932,16.99316 C11.7372,16.99316 11.4812,16.89516 11.2862,16.70016 L3.2932,8.70716 C2.9022,8.31616 2.9022,7.68316 3.2932,7.29316 C3.6832,6.90216 4.3162,6.90216 4.7072,7.29316 L11.9942,14.57916 L19.2942,7.29216 C19.6822,6.90316 20.3172,6.90216 20.7082,7.29316 C21.0972,7.68416 21.0972,8.31716 20.7062,8.70716 L12.6992,16.70116 C12.5052,16.89516 12.2492,16.99316 11.9932,16.99316"
            fill="currentColor"
            fillRule="evenodd"
          />
        )}
        {direction === 'left' && (
          <path
            d="M15.9943,21 C15.7383,21 15.4813,20.902 15.2863,20.707 L7.2923,12.7 C6.9023,12.31 6.9023,11.677 7.2933,11.287 L15.2873,3.293 C15.6783,2.902 16.3103,2.902 16.7013,3.293 C17.0923,3.684 17.0923,4.316 16.7013,4.707 L9.4143,11.994 L16.7023,19.293 C17.0923,19.684 17.0923,20.317 16.7013,20.708 C16.5063,20.902 16.2503,21 15.9943,21"
            fill="currentColor"
            fillRule="evenodd"
          />
        )}
        {direction === 'right' && (
          <path
            d="M8.0001,21 C7.7441,21 7.4881,20.902 7.2931,20.707 C6.9021,20.316 6.9021,19.684 7.2931,19.293 L14.5801,12.006 L7.2921,4.707 C6.9021,4.316 6.9021,3.683 7.2931,3.292 C7.6841,2.903 8.3171,2.902 8.7081,3.293 L16.7021,11.3 C17.0921,11.69 17.0921,12.323 16.7011,12.713 L8.7071,20.707 C8.5121,20.902 8.2561,21 8.0001,21"
            fill="currentColor"
            fillRule="evenodd"
          />
        )}
      </g>
    </svg>
  );
}
