import Bem from '@/utils/bem';
import './BackgroundImage.less';

interface Props {
  isAuth: boolean;
}

const bem = new Bem('background-image');

export default function BackgroundImage({ isAuth }: Props) {
  return <div className={bem.b({ isAuth })} />;
}
