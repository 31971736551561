import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('date-icon');

export default function DateIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M9 16.5C4.86035 16.5 1.5 13.14 1.5 9C1.5 4.85999 4.86035 1.5 9 1.5C13.1396 1.5 16.5 4.85999 16.5 9C16.5 13.14 13.1396 16.5 9 16.5Z"
        stroke="#808080"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.7822 11.3849L9.45801 9.99744C9.05273 9.75745 8.72266 9.17993 8.72266 8.7074L8.72266 5.63245"
        stroke="#808080"
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </svg>
  );
}
