import { CSSProperties, ReactNode } from 'react';
import { Modal } from 'antd';
import cx from 'classnames';
import CloseIcon from '@/icons/CloseIcon';
import Bem from '@/utils/bem';
import './ModalContainer.less';

interface Props {
  children: ReactNode;
  visible: boolean;
  className?: string;
  customMaskStyle?: CSSProperties;
  disableCloseIcon?: boolean;
  inner?: boolean;
  keyboard?: boolean;
  style?: CSSProperties;
  width?: string | number;
  setVisible: (visible: boolean) => void;
  afterClose?: () => void;
}

const bem = new Bem('common-modal');

export default function ModalContainer({
  children,
  visible,
  className,
  customMaskStyle,
  disableCloseIcon,
  inner,
  keyboard,
  style,
  width,
  setVisible,
  afterClose
}: Props) {
  const container = document.getElementById('page-content');

  const onCancel = () => setVisible(false);

  return (
    <Modal
      style={style}
      className={cx(bem.b(), className)}
      wrapClassName={bem.e('root')}
      width={width}
      open={visible}
      centered
      closable={!disableCloseIcon}
      closeIcon={<CloseIcon />}
      destroyOnClose
      footer={null}
      getContainer={inner ? container || document.body : undefined}
      keyboard={keyboard === undefined ? true : keyboard}
      mask
      maskClosable={false}
      maskStyle={customMaskStyle ? customMaskStyle : undefined}
      afterClose={afterClose}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
}
