import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('upload-icon');

export default function UploadIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M13.4141 2.58582C12.6479 1.81946 11.4146 1.80505 10.6304 2.54236L10.5859 2.58582L6.04297 7.12866C5.65234 7.51917 5.65234 8.15234 6.04297 8.54285C6.42139 8.92163 7.02832 8.93311 7.4209 8.57727L7.45703 8.54285L10.998 5.00195L10.9985 16.4895C10.9985 17.0417 11.4463 17.4895 11.9985 17.4895C12.5508 17.4895 12.9985 17.0417 12.9985 16.4895L12.998 4.99805L16.543 8.54285C16.9336 8.93347 17.5664 8.93347 17.957 8.54285C18.3359 8.16418 18.3472 7.55737 17.9917 7.16479L17.957 7.12866L13.4141 2.58582ZM21 14.7466C21.5356 14.7466 21.9727 15.1676 21.9985 15.6967L22 15.7466L22 17.4966C22 19.6794 20.2515 21.4539 18.0786 21.4958L18 21.4966L6 21.4966C3.81738 21.4966 2.04248 19.748 2.00098 17.5753L2 15.7466C2 15.1943 2.44775 14.7466 3 14.7466C3.53564 14.7466 3.97266 15.1676 3.99854 15.6967L4 15.7466L4 17.4966C4 18.5803 4.86182 19.4628 5.9375 19.4956L6 19.4966L18 19.4966C19.0835 19.4966 19.9663 18.6346 19.999 17.5588L20 17.4966L20 15.7466C20 15.1943 20.4478 14.7466 21 14.7466Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
}
