import { useCallback, useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { sendCode } from '@/api/auth';
import { updateProfile } from '@/api/user';
import ModalContainer from '@/components/modal/ModalContainer';
import Bem from '@/utils/bem';
import { showGlobalMessage } from '@/utils/function';
import './UpdateProfileModal.less';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  requestProfile: () => void;
}

const bem = new Bem('update-profile-modal');
const countDown = 60;
const phoneReg = /^1\d{10}$/;
const codeReg = /^\d{6}$/;
let timer: number;

export default function UpdateProfileModal({ visible, setVisible, requestProfile }: Props) {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [blockSendSms, setBlockSendSms] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockSendSms <= 0) {
      timer && window.clearTimeout(timer);
    } else {
      timer = window.setTimeout(() => setBlockSendSms(blockSendSms - 1), 1000);
    }
  }, [blockSendSms]);

  const close = useCallback(() => {
    setPhone('');
    setCode('');
    setBlockSendSms(0);
  }, []);

  const onSendClick = async () => {
    setBlockSendSms(countDown);

    try {
      const res = await sendCode({ phone_number: phone, operation: 'LINK_NEW_NUMBER_OTP' });

      if (res.status === 'sent') {
        showGlobalMessage('验证码发送成功', 'success');
      } else {
        showGlobalMessage('验证码发送失败', 'error');
        setBlockSendSms(0);
      }
    } catch (err) {
      setBlockSendSms(0);
    }
  };

  const onUpdate = async () => {
    setLoading(true);

    try {
      await updateProfile({ otp: code, new_phone_number: phone });
      requestProfile();
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const renderCodeInputAfter = () => {
    return (
      <Button
        className={bem.e('send-btn')}
        type="link"
        disabled={!phoneReg.test(phone) || blockSendSms > 0}
        onClick={onSendClick}
      >
        {`${blockSendSms > 0 ? `${blockSendSms}s` : '获取验证码'}`}
      </Button>
    );
  };

  return (
    <ModalContainer className={bem.b()} visible={visible} width={500} setVisible={setVisible} afterClose={close}>
      <Input.Group size="large">
        <Input
          className={bem.e('phone-input')}
          value={phone}
          addonBefore="+86"
          allowClear
          placeholder="输入手机号"
          onChange={e => setPhone(e.target.value)}
        />
        <Input
          className={bem.e('code-input')}
          value={code}
          addonAfter={renderCodeInputAfter()}
          placeholder="输入验证码"
          onChange={e => setCode(e.target.value)}
        />
      </Input.Group>
      <Button
        className={bem.e('confirm-btn')}
        block
        type="primary"
        disabled={!phoneReg.test(phone) || !codeReg.test(code)}
        loading={loading}
        onClick={onUpdate}
      >
        确认更改
      </Button>
    </ModalContainer>
  );
}
