import cx from 'classnames';
import Bem from '@/utils/bem';

interface Props {
  className?: string;
}

const bem = new Bem('create-icon');

export default function CreateIcon({ className }: Props) {
  return (
    <svg className={cx(bem.b(), className)} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.0007 2C12.553 2 13.0007 2.44775 13.0007 3L13.0007 21C13.0007 21.5522 12.553 22 12.0007 22C11.4485 22 11.0007 21.5522 11.0007 21L11.0007 13L3.00073 13C2.44849 13 2.00073 12.5522 2.00073 12C2.00073 11.4478 2.44849 11 3.00073 11L11.0007 11L11.0007 3C11.0007 2.44775 11.4485 2 12.0007 2ZM22.0005 12C22.0005 11.4478 21.5527 11 21.0005 11L13.9995 11L13.9995 13L21.0005 13C21.5527 13 22.0005 12.5522 22.0005 12Z"
        clipRule="evenodd"
        fillRule="evenodd"
        fill="#FFFFFF"
        fillOpacity="1"
      />
    </svg>
  );
}
